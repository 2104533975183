import React, { useEffect } from 'react';
import deal from '../../assets/images/handshake.avif'
import './About.scss';

function About() {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    return (
        <>
            <div className='about-us-img'>
                <div className="about-us-wrapper">
                    <div className='get-info'>
                        <div className='mb-5'>
                            <h1 className='main-heading'>Honesty, Integrity & Experience</h1>
                        </div>
                        <div className='benefit-point'>
                            <ul className='benefit-point-list'>
                                <li>We, KKN Trading are principal manufacturers of rubber products are now in the market with a complete range of Dredge Hoses, Hydraulic Hoses, Industrial Hoses, Hand Built Hoses, Molded items, and more products to follow.</li>
                                <li>We are an ISO-9001:2015 certified company developing technically challenging products including import substitutes for all Industries.</li>
                                <li>We are a young group with current business activities in Construction, Pharma Industries, Automobile etc. The group activities are managed by young entrepreneurs with varied experiences under the guidance of our mentors team who are professional directors with diversified groups having experience of managing large scale manufacturing businesses activities.</li>
                                <li>We currently have 8 wire braiders to make sizes up to 2″ & the braiders are from HM Technology Italy & Mayer Germany. We have three Yarn braiders to make different application hoses up to 1.5″ sizes. For Spiral Hoses, have 4 Spiral machines from Italy with which have produced initial lots of R12, R13, R15 , 4 SP & SH graders up to 1″ sizes.</li>
                                <li>We manufacture various Industrial products with a Brand name of “KKN Trading”. Our, present product range includes following type of Polymer Products:</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='company-img'>
                <div className='co-info'>
                    <p className='co-100'>100%</p>
                    <p className='co-detail'>“We received the best services we could hope for!”</p>
                </div>
            </div>
            <div className='deal-with'>
                <div className='deal-img'>
                    <img src={deal} alt='' />
                </div>
                <div className='deal-info'>
                    <p className='deal-heading'>WE ALSO DEAL WITH THE FOLLOWING BRANDS</p>
                    <ul className='deal-info-list'>
                        <li><span className='bullet-icon'><svg viewBox='0 0 24 24'><path d='M12 2 4 12l8 10 8-10-8-10Z'></path></svg></span><div className='deal-info-point'>EMMAFLEX - Hydraulic Hoses and Fittings</div></li>
                        <li><span className='bullet-icon'><svg viewBox='0 0 24 24'><path d='M12 2 4 12l8 10 8-10-8-10Z'></path></svg></span><div className='deal-info-point'>JINGBO - Rotary Drilling Hoses/BOP Hoses Choke and Kill Line Hoses.</div></li>
                        <li><span className='bullet-icon'><svg viewBox='0 0 24 24'><path d='M12 2 4 12l8 10 8-10-8-10Z'></path></svg></span><div className='deal-info-point'>WINDLASS - Hammer Unions, Swivel Joints, Pup Joints, Tee, Crossovers</div></li>
                        <li><span className='bullet-icon'><svg viewBox='0 0 24 24'><path d='M12 2 4 12l8 10 8-10-8-10Z'></path></svg></span><div className='deal-info-point'>VENAIR - Silicon Hoses</div></li>
                        <li><span className='bullet-icon'><svg viewBox='0 0 24 24'><path d='M12 2 4 12l8 10 8-10-8-10Z'></path></svg></span><div className='deal-info-point'>TRELLEBORG - Specialty Industrial Hoses</div></li>
                        <li><span className='bullet-icon'><svg viewBox='0 0 24 24'><path d='M12 2 4 12l8 10 8-10-8-10Z'></path></svg></span><div className='deal-info-point'>PONAFLEX - LAYFLAT PVC Hoses</div></li>
                        <li><span className='bullet-icon'><svg viewBox='0 0 24 24'><path d='M12 2 4 12l8 10 8-10-8-10Z'></path></svg></span><div className='deal-info-point'>SUNPOOL - Camloks, Clamps, Bauer Couplings, Miller Couplings</div></li>
                        <li><span className='bullet-icon'><svg viewBox='0 0 24 24'><path d='M12 2 4 12l8 10 8-10-8-10Z'></path></svg></span><div className='deal-info-point'>MANNTEK - Dry Disconnect Couplings, Break Away Couplings, Dry Gas Couplings, Dry Aviation Couplings, Dry Cryogenic Couplings, Swivel Joints, LNG Solutions</div></li>
                        <li><span className='bullet-icon'><svg viewBox='0 0 24 24'><path d='M12 2 4 12l8 10 8-10-8-10Z'></path></svg></span><div className='deal-info-point'>ABCO - Hammer Unions, Ring Gaskets and other Products</div></li>
                        <li><span className='bullet-icon'><svg viewBox='0 0 24 24'><path d='M12 2 4 12l8 10 8-10-8-10Z'></path></svg></span><div className='deal-info-point'>GOODRICH/PMT Ring Joint Gaskets with API Monogram, Spiral Wound Gaskets</div></li>
                    </ul>
                </div>
            </div>
            {/* <div className='direct-chat-appointment'>
                <div className='direct-chat-appointment-btn'>
                    <div className="appointment-wrapper">
                        <button className="app-button">
                            Book an appointment
                        </button>
                    </div>
                    <div className="appointment-wrapper">
                        <button className="app-button">
                            Book an appointment
                        </button>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default About;