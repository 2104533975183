
import '../../ProductDisplay.scss';
import IndustrialMenu from '../../product-menu/industrial-menu/IndustrialMenu';

import superAirHose from '../../../../assets/images/product/industrial-hoses/super-air-hose.png';


function SuperAirHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <IndustrialMenu activeLink="super-air-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>industrial hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>super air hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={superAirHose} alt='super air hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>Proprietary (Exceeds performance requirement of IS 446: Type 2)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Specially compounded nitrile and other synthetic rubber blend. RMA -Class C oil resistance.<br />
                                            <strong>Reinforcement:</strong> One high tenacity synthetic textile braid.<br />
                                            <strong>Cover:</strong> Weather, abrasion resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-30 ℃ to + 80 ℃ / -22℉ to + 176℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>All types of water delivery and air application in engineering, manufacturing, workshop, construction industry and irrigation.</td>
                            </tr>
                            <tr>
                                <td><strong>Recommended Fluids:</strong></td>
                                <td>Water, Compressed air (including oil mist), mild chemicals etc.</td>
                            </tr>
                            <tr>
                                <td><strong>Features:</strong></td>
                                <td>High flexibility light weight, exceeds relevant standard for performance requirement, Constant pressure rating and 1:4 safety factor</td>
                            </tr>
                            <tr>
                                <td><strong>Note:</strong></td>
                                <td>Service temperature in excess and MBR lower than recommendation may materially reduce life of the hose.
                                    <br/>
                                    1.0 Mpa = 10 bar, 1.0 Mpa = 145 psi, 1 bar = ~1.0kg/cm<span style={{ verticalAlign: "super",fontSize: "x-small"}}>2</span>
                                    <br/>
                                    Bend radius measured at inside of bend.
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>ITS XX06MF</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>-</td>
                                    <td>12.7</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>65</td>
                                    <td>130</td>
                                </tr>
                                <tr>
                                    <td>ITS XX08MF</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>-</td>
                                    <td>14.7</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>85</td>
                                    <td>175</td>
                                </tr>
                                <tr>
                                    <td>ITS XX10MF</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>16.7</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>90</td>
                                    <td>210</td>
                                </tr>
                                <tr>
                                    <td>ITS XX12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>20.7</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>115</td>
                                    <td>305</td>
                                </tr>
                                <tr>
                                    <td>ITS XX16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>23.7</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>140</td>
                                    <td>350</td>
                                </tr>
                                <tr>
                                    <td>ITS XX19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>28.2</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>152</td>
                                    <td>510</td>
                                </tr>
                                <tr>
                                    <td>ITS XX25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>35.3</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>200</td>
                                    <td>720</td>
                                </tr>
                                <tr>
                                    <td>ITS XX31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>-</td>
                                    <td>43.9</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>250</td>
                                    <td>1090</td>
                                </tr>
                                <tr>
                                    <td>ITS XX38MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>-</td>
                                    <td>50.3</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>305</td>
                                    <td>1270</td>
                                </tr>
                                <tr>
                                    <td>ITS XX51MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>-</td>
                                    <td>63.5</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>355</td>
                                    <td>1725</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuperAirHose;