import { useEffect, useState } from 'react';
import './ContactUs.scss';

function ContactUs() {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isErrorOnFromSubmit, setIsErrorOnFromSubmit] = useState(false);

    const validateForm = () => {
        setName(name.trim());
        setEmail(email.trim());
        setMessage(message.trim());

        let errors = {};
        if (!name) {
            errors.name = "Name is required";
        }
        if (!email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Email address is invalid";
        }
        if (!number) {
            errors.number = "Mobile Number is required";
        } else if (number.length < 10) {
            errors.number = "Invalid Mobile Number";
        }
        if (!message) {
            errors.message = "Message is required";
        }
        return errors;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isFormSubmitted) {
            return;
        }
        const errors = validateForm();

        // If there are errors, set the state with the error object
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {

            const submitContactUs = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/contact-us`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ name, email, number, message })
                    });

                    if (response.ok && response.status === 201) {
                        setName("");
                        setEmail("");
                        setNumber("");
                        setMessage("");
                        setErrors({});
                        setIsFormSubmitted(true);
                        setTimeout(() => {
                            setIsFormSubmitted(false);
                        }, 5000);
                    } else if (!response.ok && response.status === 400) {
                        console.log(response);
                        setIsErrorOnFromSubmit(true);
                        setTimeout(() => {
                            setIsErrorOnFromSubmit(false);
                        }, 10000);
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            submitContactUs();
        }
    }

    return (
        <div className='contact-us-wrapper'>
            <div className='contact-form-info'>
                <div className='contact-info'>
                    <h1 className='main-heading mb-5'>CONTACT INFO</h1>
                    <ul className='contact-us-list'>
                        <li>
                            <span>
                                <svg className="contact-us-icon" fill="#051320" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                            </span>
                            <div className='contact-us-inner-info'>+971 551785051</div>
                        </li>
                        <li>
                            <span>
                                <svg className="contact-us-icon"
                                    viewBox="0 0 512 512" height="1em"  >
                                    <g>
                                        <path d="M442.18,46.55V23.27h-46.55c-38.57,0.02-69.8,31.25-69.82,69.82v325.82c0.02,38.57,31.25,69.8,69.82,69.82h46.55
		c38.57-0.02,69.8-31.25,69.82-69.82V93.09c-0.02-38.57-31.25-69.8-69.82-69.82V46.55v23.27c12.84,0.02,23.25,10.43,23.27,23.27
		v325.82c-0.02,12.84-10.43,23.25-23.27,23.27h-46.55c-12.84-0.02-23.25-10.43-23.27-23.27V93.09
		c0.02-12.84,10.43-23.25,23.27-23.27l46.55,0V46.55z"/>
                                        <path d="M349.09,46.55h-256C41.67,46.56,0.02,88.21,0,139.64v232.73c0.02,51.42,41.67,93.08,93.09,93.09h256
		c12.85,0,23.27-10.42,23.27-23.27c0-12.85-10.42-23.27-23.27-23.27h-256c-25.7-0.05-46.5-20.85-46.55-46.55V139.64
		c0.05-25.7,20.85-46.5,46.55-46.55h256c12.85,0,23.27-10.42,23.27-23.27C372.36,56.96,361.94,46.55,349.09,46.55L349.09,46.55z"/>
                                        <path d="M256,162.91v-23.27H116.36c-6.13,0-12.12,2.48-16.46,6.82c-4.33,4.33-6.82,10.33-6.82,16.46v69.82
		c0,6.13,2.48,12.12,6.82,16.46c4.33,4.33,10.33,6.82,16.46,6.82H256c6.13,0,12.12-2.48,16.46-6.82c4.33-4.33,6.82-10.33,6.82-16.46
		v-69.82c0-6.13-2.48-12.12-6.82-16.46c-4.33-4.33-10.33-6.82-16.46-6.82V162.91h-23.27v46.55h-93.09v-23.27H256V162.91h-23.27H256z
		"/>
                                        <path d="M232.73,302.55v0.23c0,12.85,10.42,23.27,23.27,23.27c12.85,0,23.27-10.42,23.27-23.27v-0.23
		c0-12.85-10.42-23.27-23.27-23.27C243.15,279.27,232.73,289.69,232.73,302.55"/>
                                        <path d="M162.91,302.55v0.23c0,12.85,10.42,23.27,23.27,23.27c12.85,0,23.27-10.42,23.27-23.27v-0.23
		c0-12.85-10.42-23.27-23.27-23.27C173.33,279.27,162.91,289.69,162.91,302.55"/>
                                        <path d="M93.09,302.55v0.23c0,12.85,10.42,23.27,23.27,23.27c12.85,0,23.27-10.42,23.27-23.27v-0.23
		c0-12.85-10.42-23.27-23.27-23.27C103.51,279.27,93.09,289.69,93.09,302.55"/>
                                        <path d="M232.73,372.36v0.23c0,12.85,10.42,23.27,23.27,23.27c12.85,0,23.27-10.42,23.27-23.27v-0.23
		c0-12.85-10.42-23.27-23.27-23.27C243.15,349.09,232.73,359.51,232.73,372.36"/>
                                        <path d="M162.91,372.36v0.23c0,12.85,10.42,23.27,23.27,23.27c12.85,0,23.27-10.42,23.27-23.27v-0.23
		c0-12.85-10.42-23.27-23.27-23.27C173.33,349.09,162.91,359.51,162.91,372.36"/>
                                        <path d="M93.09,372.36v0.23c0,12.85,10.42,23.27,23.27,23.27c12.85,0,23.27-10.42,23.27-23.27v-0.23
		c0-12.85-10.42-23.27-23.27-23.27C103.51,349.09,93.09,359.51,93.09,372.36"/>
                                    </g>
                                </svg>
                            </span>
                            <div className='contact-us-inner-info'>+971 43434445</div>
                        </li>
                        <li>
                            <span>
                                <svg className="contact-us-icon" fill="#051320" height="1em"
                                    viewBox="0 0 512 512">
                                    <g>
                                        <path d="M128,112.5c-53.02,0-96,42.98-96,96v192h192v-192C224,155.48,181.02,112.5,128,112.5z M128,80.5h256
		c70.69,0,128,57.31,128,128v192c0,17.67-14.33,32-32,32H32c-17.67,0-32-14.33-32-32v-192C0,137.81,57.31,80.5,128,80.5z
		 M212.67,112.5c27.58,24.27,43.37,59.26,43.33,96v192h224v-192c0-53.02-42.98-96-96-96H212.67z"/>
                                        <path d="M377.38,256.5H288v-32h160c8.84,0,16,7.16,16,16v32c0,8.84-7.16,16-16,16h-32c-4.25,0.01-8.32-1.67-11.33-4.67
		L377.38,256.5z M160,208.5c0,17.66-14.34,0-32,0s-32,17.66-32,0c0-17.67,14.33-32,32-32S160,190.83,160,208.5z"/>
                                    </g>
                                </svg>
                            </span>
                            <div className='contact-us-inner-info'>PO Box: 124080</div>
                        </li>
                        <li>
                            <span>
                                <svg className="contact-us-icon" fill="#051320" height="1em" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" /></svg>
                            </span>

                            <div className='contact-us-inner-info'>marketing@mail.com</div>
                        </li>
                        <li>
                            <span>
                                <svg className="contact-us-icon" fill="#051320" height="1em" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" /></svg>
                            </span>
                            <div className='contact-us-inner-info'>office@mail.com</div>
                        </li>
                        <li>
                            <span>
                                <svg className="contact-us-icon" fill="#051320" height="1em" viewBox="0 0 576 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 256h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm256-32H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16z" /></svg>
                            </span>
                            <div className='contact-us-inner-info'>Corporate office: WH No. 10,
                                Ras Al Khor Industrial Area,
                                Dubai, UAE
                            </div>
                        </li>
                    </ul>
                    <div className='contact-us-divider'></div>
                </div>
                <div className='contact-form'>
                    <h1 className='mb-5 main-heading'>GET IN TOUCH</h1>
                    <div className='contact-us-form'>
                        <form className='contact-us-form-grid' onSubmit={handleSubmit}>
                            <div className={"form-name " + ((errors.name && !name) ? "invalid" : "")}>
                                <input type='text' name="name" placeholder='Your name*' value={name} onChange={(event) => setName(event.target.value)} required />
                            </div>
                            <div className={"form-email " + ((errors.email && !email) ? "invalid" : "")}>
                                <input type='email' name="email" placeholder='Email*' value={email} onChange={(event) => setEmail(event.target.value)} required />
                            </div>
                            <div className={"form-message " + ((errors.message && !message) ? "invalid" : "")}>
                                <textarea type='text' rows="4" name='message' placeholder="Message*" value={message} onChange={(event) => setMessage(event.target.value)} required />
                            </div>
                            <div className={"form-number " + ((errors.number) ? "invalid" : "")}>
                                <input type='number' name='number' placeholder="Phone number*" value={number} onChange={(event) => event.target.value.length <= 10 && setNumber(event.target.value)} required />
                            </div>
                            <div className='form-send-msg-btn'>
                                <button>SEND MESSAGE</button>
                            </div>
                        </form>
                        <h3 id='formSubmittedMessage' className={"form-submitted " + (isFormSubmitted ? "submitted" : "")}>Message sent successfully.</h3>
                        <h3 id='formErrorMessage' className={"form-not-submit " + (isErrorOnFromSubmit ? "not-submit" : "")}>Message not sent, Please try agin alter.</h3>
                    </div>
                </div>
            </div>
            <div className='contact-map'>
                <iframe title='Company Address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d951842.2143639999!2d73.01214117269397!3d21.266176588576002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdfda0af9aee793%3A0x1b91a69afe87df12!2sBAPS%20Swaminarayan%20Mandir!5e0!3m2!1sen!2sin!4v1688553034129!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referre="" rpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div >
    )
}
export default ContactUs