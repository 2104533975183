
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import automotiveAirBrakeHose from '../../../../assets/images/product/special-hoses/automotive-air-brake-hose.jpeg';


function AutomotiveAirBrakeHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="automotive-air-brake-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>automotive air brake hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={automotiveAirBrakeHose} alt='automotive air brake hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>SAE J 1402-Type A</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Black EPDM rubber blend.<br />
                                            <strong>Reinforcement:</strong> Two high tenacity synthetic textile braid.<br />
                                            <strong>Cover:</strong> Black EPDM rubber blend resistant weather, abrasion, ozone and heat.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-40 ℃ to + 100 ℃ / -40℉ to + 212℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Used as flexible joint in automotive brake system.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>ITM 1JJ06</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>-</td>
                                    <td>15.5</td>
                                    <td>15</td>
                                    <td>220</td>
                                    <td>75</td>
                                    <td>1100</td>
                                    <td>65</td>
                                    <td>220</td>
                                </tr>
                                <tr>
                                    <td>ITM 1JJ08</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>-</td>
                                    <td>17.2</td>
                                    <td>15</td>
                                    <td>220</td>
                                    <td>75</td>
                                    <td>1100</td>
                                    <td>75</td>
                                    <td>260</td>
                                </tr>
                                <tr>
                                    <td>ITM 1JJ10</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>19.0</td>
                                    <td>15</td>
                                    <td>220</td>
                                    <td>75</td>
                                    <td>1100</td>
                                    <td>90</td>
                                    <td>280</td>
                                </tr>
                                <tr>
                                    <td>ITM 1JJ12</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>22.2</td>
                                    <td>15</td>
                                    <td>220</td>
                                    <td>75</td>
                                    <td>1100</td>
                                    <td>100</td>
                                    <td>340</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AutomotiveAirBrakeHose;