
import '../../ProductDisplay.scss';
import IndustrialMenu from '../../product-menu/industrial-menu/IndustrialMenu';

import carbonFreeHose from '../../../../assets/images/product/industrial-hoses/carbon-free-hose.jpeg';


function CarbonFreeHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <IndustrialMenu activeLink="carbon-free-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>industrial hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>carbon free hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={carbonFreeHose} alt='carbon free hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>KKN Trading Proprietary</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Off white specially compounded non-conductive synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> One or two high tenacity synthetic textile braid.<br />
                                            <strong>Cover:</strong> Green weather and ozone resistant synthetic rubber blend.Glass fiber cover also available on request for additional protection from heat, flame and Molten metal.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-40 ℃ to + 100 ℃ / -40℉ to + 212℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Furnace door coolant, electrical cable coolant for electric arc furnace in steel industry and other non- conductive application.</td>
                            </tr>
                            <tr>
                                <td><strong>Recommended fluids:</strong></td>
                                <td>Water</td>
                            </tr>
                            <tr>
                                <td><strong>Features:</strong></td>
                                <td>Low leakage current of less than 20μA at 6000volt DC. Constant pressure rating and 1:4 safety factor. Glass fiber cover are specially designed to product hose from damage due to splash of molten metal, heat and open flame.2 1/2" and 3.0" are rigid mandrel.</td>
                            </tr>
                            <tr>
                                <td><strong>Note:</strong></td>
                                <td>Service temperature in excess and MBR lower than recommendation may materially reduce life of the hose
                                    <br/>
                                    1.0 Mpa = 10bar, 1.0 Mpa = 145 psi, 1 bar = ~1.0 kg/cm<span style={{ verticalAlign: "super",fontSize: "x-small"}}>2</span>
                                    <br/>
                                    Bend radius measured at inside of bend.
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>ITS 1AA12MF</td>
                                    <td>3/8"</td>
                                    <td>9.5</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>17.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>90</td>
                                    <td>230</td>
                                </tr>
                                <tr>
                                    <td>ITS 1AA12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>21.6</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>100</td>
                                    <td>350</td>
                                </tr>
                                <tr>
                                    <td>ITS 1AA19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>31.2</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>130</td>
                                    <td>710</td>
                                </tr>
                                <tr>
                                    <td>ITS 1AA25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>36.5</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>150</td>
                                    <td>820</td>
                                </tr>
                                <tr>
                                    <td>ITS 1AA31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>-</td>
                                    <td>45.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>190</td>
                                    <td>1140</td>
                                </tr>
                                <tr>
                                    <td>ITS 1AA38MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>-</td>
                                    <td>53.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>205</td>
                                    <td>1460</td>
                                </tr>
                                <tr>
                                    <td>ITS 1AA51MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>-</td>
                                    <td>65.5</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>310</td>
                                    <td>1970</td>
                                </tr>
                                <tr>
                                    <td>ITS 1AA63MF</td>
                                    <td>21/2"</td>
                                    <td>63</td>
                                    <td>-40</td>
                                    <td>63</td>
                                    <td>-</td>
                                    <td>81.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>510</td>
                                    <td>2750</td>
                                </tr>
                                <tr>
                                    <td>ITS 1AA76MF</td>
                                    <td>3.0"</td>
                                    <td>76</td>
                                    <td>-48</td>
                                    <td>76</td>
                                    <td>-</td>
                                    <td>94.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>640</td>
                                    <td>3320</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarbonFreeHose;