
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import superSprayHose from '../../../../assets/images/product/special-hoses/super-spray-hose.png';


function SuperSprayHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="super-spray-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>super spray hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={superSprayHose} alt='super spray hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>IS 1677-Type C. Exceeds performance requirements.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> One high tenacity synthetic textile braid.<br />
                                            <strong>Cover:</strong> Weather, abrasion, ozone and heat resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-30℃ to + 80℃ / -22℉ to + 176℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Pressure spraying of agro pharmaceutical and /or fertilizer products in agriculture, forestry etc.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                            <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>ITS QQ10MF</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>19.0</td>
                                    <td>60</td>
                                    <td>870</td>
                                    <td>240</td>
                                    <td>3480</td>
                                    <td>100</td>
                                    <td>290</td>
                                </tr>
                                <tr>
                                    <td>ITS QQ12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>22.0</td>
                                    <td>60</td>
                                    <td>870</td>
                                    <td>240</td>
                                    <td>3480</td>
                                    <td>130</td>
                                    <td>370</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuperSprayHose;