import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Products.scss'


function Products() {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    return (
        <div className="products-section">
            <h1 className='title'>Products</h1>

            <div className='products-container'>
                <div className='products-wrapper'>
                    <div className='products-title-wrapper'>
                        <div className='products-title'>
                            {/* <p>Industrial Hoses</p> */}
                            <h3>industrial hoses</h3>
                        </div>
                    </div>
                    <div className='products-list-container'>
                        <div className='products-list-wrapper'>
                            <div className='product'>
                                <h4> <NavLink to="industrial/air-water-hose">air water hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="industrial/air-pneumatic-tool-hose">air / pneumatic tool hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="industrial/carbon-free-hose">carbon free hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="industrial/rock-drill-hose">rock drill hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="industrial/super-rock-drill-hose">super rock drill hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="industrial/steam-hose">steam hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="industrial/super-steam-hose">super steam hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="industrial/sand-blast-hose">sand blast hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="industrial/super-sand-blast-hose">super sand blast hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="industrial/super-air-hose">super air hose</NavLink></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='products-wrapper'>
                    <div className='products-title-wrapper'>
                        <div className='products-title'>
                            {/* <p>Hydraulic Hoses</p> */}
                            <h3>hydraulic hoses</h3>
                        </div>
                    </div>
                    <div className='products-list-container'>
                        <div className='products-list-wrapper'>
                            <div className='product'>
                                <h4> <NavLink to="hydraulic/100-r1-at-1sn-hose">100 r1 at / 1sn hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="hydraulic/100-r1-st-hose(thicker-cover)">100 r1 st hose(thicker cover)</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="hydraulic/100-r1-high-temperature-hose">100 r1 high temperature hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="hydraulic/100-r2-at-2sn-hose">100 r2 at / 2sn hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="hydraulic/100-r2-st-hose(thicker-cover)">100 r2 st hose(thicker cover)</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="hydraulic/100-r2-high-temperature-hose">100 r2 high temperature hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="hydraulic/100-r5-r-hose">100 r5 r hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="hydraulic/100-r6-1te-hose">100 r6 / 1te hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="hydraulic/100-r6-high-temperature-hose">100 r6 high temperature hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="hydraulic/100-r3-2te-hose">100 r3 / 2te hose</NavLink></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='products-wrapper'>
                    <div className='products-title-wrapper'>
                        <div className='products-title'>
                            {/* <p>Spiral Hoses</p> */}
                            <h3>spiral hoses</h3>
                        </div>
                    </div>
                    <div className='products-list-container'>
                        <div className='products-list-wrapper'>
                            <div className='product'>
                                <h4> <NavLink to="spiral/hipulse-4sp">hipulse 4sp</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="spiral/hipulse-4sh">hipulse 4sh</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="spiral/hipulse-r12">hipulse r12</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="spiral/hipulse-r13">hipulse r13</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="spiral/hipulse-r15">hipulse r15</NavLink></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='products-wrapper fixed-grid'>
                    <div className='products-title-wrapper'>
                        <div className='products-title'>
                            {/* <p>Special Hoses</p> */}
                            <h3>special hoses</h3>
                        </div>
                    </div>
                    <div className='products-list-container'>
                        <div className='products-list-wrapper'>
                            <div className='product'>
                                <h4> <NavLink to="special/compact-1sc-hose">compact-1sc hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/compact-2sc-hose">compact-2sc hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/compactcylinder-hose">compactcylinder hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/1and2-wireagro-hose">1and2_wireagro hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/jack-hose">jack hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/jet-wash-r1-hose">jet wash-r1 hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/jet-wash-r2-hose">jet wash-r2 hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/jet-wash-compact-1sc-hose">jet wash compact-1sc hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/jet-wash-compact-2sc-hose">jet wash-compact 2sc hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/jet-wash-3000-psi-hose">jet wash-3000 psi hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/jet-wash-4000-psi-hose">jet wash-4000 psi hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/jet-wash-5000-psi-hose">jet wash-5000 psi hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/petsel-dispensing-hose">petsel-dispensing hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/1-wire-lpg-hose">1-wire lpg hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/2textile-lpg-hose">2textile-lpg hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/fuel-dispensing-hose">fuel-dispensing hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/multiwash-hose">multiwash hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/super-spray-hose">super spray hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/chemical-hose">chemical hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/rig-hose">rig hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/righose-ht">righose-ht</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="special/automotive-air-brake-hose">automotive air brake hose</NavLink></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='products-wrapper'>
                    <div className='products-title-wrapper'>
                        <div className='products-title'>
                            {/* <p>Dredge Hoses</p> */}
                            <h3>dredge hoses</h3>
                        </div>
                    </div>
                    <div className='products-list-container'>
                        <div className='products-list-wrapper'>
                            <div className='product'>
                                <h4> <NavLink to="dredge/discharge-dredging-hose">discharge dredging hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="dredge/suction-dredging-hose">suction dredging hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="dredge/floating-hose">floating hose</NavLink></h4>
                            </div>
                            <div className='product'>
                                <h4> <NavLink to="dredge/expansion-hose">expansion hose</NavLink></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
