
import '../../ProductDisplay.scss';
import HydraulicMenu from '../../product-menu/hydraulic-menu/HydraulicMenu';

import prod100R5rHose from '../../../../assets/images/product/hydraulic-hoses/100-r5-r-hose.jpeg';


function Prod100R5rHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <HydraulicMenu activeLink="100-r5-r-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>hydraulic hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>100 r5 r hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={prod100R5rHose} alt='100 r5 r hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>SAE 100 R5</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend. One textile inner braid over tube<br />
                                            <strong>Reinforcement:</strong> One high tensile carbon steel wire braid over textile layer.<br />
                                            <strong>Cover:</strong> Oil,weather, abrasion,ozone and heat resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-40 ℃ to + 100 ℃ / -40℉ to + 212℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Hydraulic system low-medium pressure lines, general purpose and agriculture application.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>HWS Q 06MF</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td><span className='text-danger'>-5</span></td>
                                    <td>6</td>
                                    <td>-</td>
                                    <td>14.6</td>
                                    <td>210</td>
                                    <td>3045</td>
                                    <td>840</td>
                                    <td>12180</td>
                                    <td>85</td>
                                    <td>250</td>
                                </tr>
                                <tr>
                                    <td>HWS Q 08MF</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td><span className='text-danger'>-6</span></td>
                                    <td>8</td>
                                    <td>-</td>
                                    <td>16.7</td>
                                    <td>157</td>
                                    <td>2277</td>
                                    <td>628</td>
                                    <td>9106</td>
                                    <td>100</td>
                                    <td>320</td>
                                </tr>
                                <tr>
                                    <td>HWS Q 11MF</td>
                                    <td>13/32"</td>
                                    <td>11</td>
                                    <td><span className='text-danger'>-8</span></td>
                                    <td>11</td>
                                    <td>-</td>
                                    <td>19.5</td>
                                    <td>140</td>
                                    <td>2030</td>
                                    <td>560</td>
                                    <td>8120</td>
                                    <td>115</td>
                                    <td>390</td>
                                </tr>
                                <tr>
                                    <td>HWS Q 12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td><span className='text-danger'>-10</span></td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>23.0</td>
                                    <td>122</td>
                                    <td>1769</td>
                                    <td>488</td>
                                    <td>7076</td>
                                    <td>140</td>
                                    <td>510</td>
                                </tr>
                                <tr>
                                    <td>HWS Q 16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td><span className='text-danger'>-12</span></td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>27.0</td>
                                    <td>105</td>
                                    <td>1523</td>
                                    <td>420</td>
                                    <td>6090</td>
                                    <td>165</td>
                                    <td>650</td>
                                </tr>
                                <tr>
                                    <td>HWS Q 22MF</td>
                                    <td>7/8"</td>
                                    <td>22</td>
                                    <td><span className='text-danger'>-16</span></td>
                                    <td>22</td>
                                    <td>-</td>
                                    <td>31.7</td>
                                    <td>56</td>
                                    <td>812</td>
                                    <td>224</td>
                                    <td>3248</td>
                                    <td>185</td>
                                    <td>670</td>
                                </tr>
                                <tr>
                                    <td>HWS Q 28MF</td>
                                    <td>11/8"</td>
                                    <td>29</td>
                                    <td><span className='text-danger'>-20</span></td>
                                    <td>28</td>
                                    <td>-</td>
                                    <td>38.3</td>
                                    <td>43</td>
                                    <td>624</td>
                                    <td>172</td>
                                    <td>2494</td>
                                    <td>230</td>
                                    <td>860</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prod100R5rHose;