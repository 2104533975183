
import { NavLink } from 'react-router-dom';
import LOGO from '../../assets/images/company-logo.png';
import './Footer.scss';


function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="brand-wrapper">
        <NavLink to="">
          <img src={LOGO} className="brand" alt="" />
        </NavLink>
        <p className="message">KKN Trading has been producing and distributing high quality products all over the world, offering an excellent service and customized solutions for every customer needs.</p>
        {/* <div className='appointment-wrapper'>
          <button className='app-button clo-sec'>Order Now</button>
        </div> */}
      </div>
      {/* <div className='inner-footer-wrapper'>
        <h3 className='footer-classic-title'>ABOUT</h3>
        <ul className='footer-classic-list'>
          <li>
            <NavLink to="about-us">About Us</NavLink>
          </li>
          <li>
            <NavLink to="career">Career</NavLink>
          </li>
          <li>
            <NavLink to="press-and-media">Press and Media</NavLink>
          </li>
        </ul>
      </div>
      <div className='inner-footer-wrapper'>
        <h3 className='footer-classic-title'>QUICK LINK</h3>
        <ul className='footer-classic-list'>
          <li>
            <NavLink to="service">Service</NavLink>
          </li>
          <li>
            <NavLink to="blog">Blog</NavLink>
          </li>
          <li>
            <NavLink to="pricing">Pricing</NavLink>
          </li>
          <li>
            <NavLink to="privacy-policy">Privacy Policy</NavLink>
          </li>
        </ul>
      </div> */}
      <div className='inner-footer-wrapper'>
        <h3 className='footer-classic-title'>CONTACT</h3>
        <ul className='footer-classic-list'>
          <li>
            <a href="mailto:contact@savyasachiinfotech.com">info@demolink.org</a>
          </li>
          <li>
            <a href="tel:++971 43434445">Landline: +971 43434445</a>
          </li>
          


          <li>
            <a href="tel:+971 551785051">Mobile: +971 551785051</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
