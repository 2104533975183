
import '../../ProductDisplay.scss';
import SpiralMenu from '../../product-menu/spiral-menu/SpiralMenu';

import hipulse4sp from '../../../../assets/images/product/spiral-hoses/hipulse-4sp.jpeg';


function Hipulse4sp() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpiralMenu activeLink="hipulse-4sp" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>spiral hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>hipulse 4sp</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={hipulse4sp} alt='hipulse 4sp'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>EN 856 4SP, ISO 3862-1 4SP</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> 4 Spiral Layer of High Tensile steel wire.<br />
                                            <strong>Cover:</strong> Oil, weather, abrasion resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>For hydraulic fluids: -40 ℃ to + 100 ℃ / -40℉ to + 212℉.
                                    <br/>Upto 120℉ / 248℉ maximum for 10% of total working hrs.<br />
                                Exception: Air: max+ 70 ℃ / 158 ℉ and Water: max+ 85 ℃ / 185℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Heavy duty impulsive high pressure hydraulic applications like excavators, dosers, dumpers, cranes, compactors, rock breakers, hydraulic drill rigs & other construction equipment's.</td>
                            </tr>
                            <tr>
                                <td><strong>Recommended fluids:</strong></td>
                                <td>Suitable for petroleum based hydraulic fluids, synthetic esters, biodegradable hydraulic fluids, water glycol based fluids & air.</td>
                            </tr>
                            <tr>
                                <td><strong>Features:</strong></td>
                                <td>Qualified for 1 million impulse cycle which is more than twice to relevant standard requirements. Good flexibility for compact hydraulic circuits. Good weather & ozone resistance.</td>
                            </tr>
                            <tr>
                                <td><strong>NOTE:</strong></td>
                                <td>Service temperature in excess of 100℃ / 212℉ and MBR lower than recommendation may materially reduce life of the hose.
                                    <br/>1.0 Mpa = 10bar, 1.0 Mpa = 145 psi, 1bar = ~1.0 kg /cm<span style={{ verticalAlign: "super",fontSize: "x-small"}}>2</span>
                                    <br/>Bend radius measured at inside of bend.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>M4SP-08</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>25.0</td>
                                    <td>425</td>
                                    <td>6150</td>
                                    <td>1700</td>
                                    <td>24600</td>
                                    <td>230</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>M4SP-10</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>28.0</td>
                                    <td>400</td>
                                    <td>5800</td>
                                    <td>1600</td>
                                    <td>23200</td>
                                    <td>250</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>M4SP-12</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>31.8</td>
                                    <td>380</td>
                                    <td>5500</td>
                                    <td>1520</td>
                                    <td>22000</td>
                                    <td>300</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>M4SP-16</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>39.0</td>
                                    <td>320</td>
                                    <td>4650</td>
                                    <td>1280</td>
                                    <td>18600</td>
                                    <td>340</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hipulse4sp;