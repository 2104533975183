
import '../../ProductDisplay.scss';
import HydraulicMenu from '../../product-menu/hydraulic-menu/HydraulicMenu';

import prod100R2highTemperatureHose from '../../../../assets/images/product/hydraulic-hoses/100-r2-high-temperature-hose.jpeg';


function Prod100R2highTemperatureHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <HydraulicMenu activeLink="100-r2-high-temperature-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>hydraulic hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>100 r2 high temperature hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={prod100R2highTemperatureHose} alt='100 r2 high temperature hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>SAE J517 100R2S AT / EN853 2SN / ISO 1436-1 2SN</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> One high tensile carbon steel wire braid.<br />
                                            <strong>Cover:</strong> Oil,weather, abrasion,ozone and heat resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-40 ℃ to + 135 ℃ / -40℉ to + 275℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Hydraulic system low-medium pressure lines and return lines.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>HWS R210006MF</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>12.8</td>
                                    <td>15.0</td>
                                    <td>400</td>
                                    <td>5800</td>
                                    <td>1600</td>
                                    <td>23200</td>
                                    <td>75</td>
                                    <td>360</td>
                                </tr>
                                <tr>
                                    <td>HWS R210008MF</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>14.2</td>
                                    <td>16.4</td>
                                    <td>350</td>
                                    <td>5075</td>
                                    <td>1400</td>
                                    <td>20300</td>
                                    <td>86</td>
                                    <td>405</td>
                                </tr>
                                <tr>
                                    <td>HWS R210010MF</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>16.7</td>
                                    <td>19.0</td>
                                    <td>330</td>
                                    <td>4785</td>
                                    <td>1320</td>
                                    <td>19140</td>
                                    <td>94</td>
                                    <td>530</td>
                                </tr>
                                <tr>
                                    <td>HWS R210012MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>19.7</td>
                                    <td>21.9</td>
                                    <td>275</td>
                                    <td>3988</td>
                                    <td>1100</td>
                                    <td>15950</td>
                                    <td>135</td>
                                    <td>630</td>
                                </tr>
                                <tr>
                                    <td>HWS R210016MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>23.0</td>
                                    <td>25.4</td>
                                    <td>250</td>
                                    <td>3625</td>
                                    <td>1000</td>
                                    <td>14500</td>
                                    <td>150</td>
                                    <td>790</td>
                                </tr>
                                <tr>
                                    <td>HWS R210019MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>26.9</td>
                                    <td>29.3</td>
                                    <td>215</td>
                                    <td>3118</td>
                                    <td>860</td>
                                    <td>12470</td>
                                    <td>180</td>
                                    <td>950</td>
                                </tr>
                                <tr>
                                    <td>HWS R210025MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>34.8</td>
                                    <td>38.1</td>
                                    <td>165</td>
                                    <td>2393</td>
                                    <td>660</td>
                                    <td>9570</td>
                                    <td>225</td>
                                    <td>1430</td>
                                </tr>
                                <tr>
                                    <td>HWS R210031MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>44.5</td>
                                    <td>48.3</td>
                                    <td>125</td>
                                    <td>1813</td>
                                    <td>500</td>
                                    <td>7250</td>
                                    <td>315</td>
                                    <td>2060</td>
                                </tr>
                                <tr>
                                    <td>HWS R210038MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>50.8</td>
                                    <td>54.6</td>
                                    <td>90</td>
                                    <td>1305</td>
                                    <td>360</td>
                                    <td>5220</td>
                                    <td>375</td>
                                    <td>2370</td>
                                </tr>
                                <tr>
                                    <td>HWS R210051MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>63.5</td>
                                    <td>67.3</td>
                                    <td>80</td>
                                    <td>1160</td>
                                    <td>320</td>
                                    <td>4640</td>
                                    <td>473</td>
                                    <td>3070</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prod100R2highTemperatureHose;