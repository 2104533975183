
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import chemicalHose from '../../../../assets/images/product/special-hoses/chemical-hose.png';


function ChemicalHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="chemical-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>chemical hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={chemicalHose} alt='chemical hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>IS 7654: Type 1</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Specially compounded EPDM black.<br />
                                            <strong>Reinforcement:</strong> High tenacity synthetic textile braid.<br />
                                            <strong>Cover:</strong> Specially compounded EPDM black. Embossed branding.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-30℃ to + 100℃ / -22℉ to + 212℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Discharging all types of <strong>dilute chemicals</strong> such as mineral acids, mineral alkalis,inorganic salts, common alcohols etc. <strong className='text-danger'>Not for abobe concentrated chemicals.</strong></td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>ITS 1MM 12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>22.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>127</td>
                                    <td>310</td>
                                </tr>
                                <tr>
                                    <td>ITS 1MM 16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>26.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>140</td>
                                    <td>380</td>
                                </tr>
                                <tr>
                                    <td>ITS 1MM 19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>30.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>152</td>
                                    <td>510</td>
                                </tr>
                                <tr>
                                    <td>ITS 1MM 25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>36.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>203</td>
                                    <td>605</td>
                                </tr>
                                <tr>
                                    <td>ITS 1MM 31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>-</td>
                                    <td>44.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>254</td>
                                    <td>910</td>
                                </tr>
                                <tr>
                                    <td>ITS1MM 38MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>-</td>
                                    <td>51.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>305</td>
                                    <td>1120</td>
                                </tr>
                                <tr>
                                    <td>ITS 1MM 51MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>-</td>
                                    <td>66.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>355</td>
                                    <td>1630</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChemicalHose;