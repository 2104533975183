
import '../../ProductDisplay.scss';
import HydraulicMenu from '../../product-menu/hydraulic-menu/HydraulicMenu';

import prod100R61teHose from '../../../../assets/images/product/hydraulic-hoses/100-r6-1te-hose.jpeg';


function Prod100R61teHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <HydraulicMenu activeLink="100-r6-1te-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>hydraulic hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>100 r6 / 1te hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={prod100R61teHose} alt='100 r6 1te hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>SAE J 517-100R6 / EN854 R6 / EN854-1TE / ISO 4079-R6-1TE</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> One high tensile carbon steel wire braid over textile braid.<br />
                                            <strong>Cover:</strong> Oil,weather, abrasion,ozone and heat resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-40 ℃ to + 100 ℃ / -40℉ to + 212℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Hydraulic system low pressure, drain and return lines.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>HTS F06MF</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>-</td>
                                    <td>12.7</td>
                                    <td>28</td>
                                    <td>406</td>
                                    <td>112</td>
                                    <td>1624</td>
                                    <td>65</td>
                                    <td>130</td>
                                </tr>
                                <tr>
                                    <td>HTS F08MF</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>-</td>
                                    <td>14.3</td>
                                    <td>28</td>
                                    <td>406</td>
                                    <td>112</td>
                                    <td>1624</td>
                                    <td>75</td>
                                    <td>160</td>
                                </tr>
                                <tr>
                                    <td>HTS F10MF</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>16.0</td>
                                    <td>28</td>
                                    <td>406</td>
                                    <td>112</td>
                                    <td>1624</td>
                                    <td>75</td>
                                    <td>170</td>
                                </tr>
                                <tr>
                                    <td>HTS F12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>19.8</td>
                                    <td>28</td>
                                    <td>406</td>
                                    <td>112</td>
                                    <td>1624</td>
                                    <td>100</td>
                                    <td>250</td>
                                </tr>
                                <tr>
                                    <td>HTS F16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>23.0</td>
                                    <td>28</td>
                                    <td>406</td>
                                    <td>112</td>
                                    <td>1624</td>
                                    <td>125</td>
                                    <td>305</td>
                                </tr>
                                <tr>
                                    <td>HTS F19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>26.5</td>
                                    <td>28</td>
                                    <td>406</td>
                                    <td>112</td>
                                    <td>1624</td>
                                    <td>150</td>
                                    <td>385</td>
                                </tr>
                                <tr>
                                    <td>HTS F25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>33.5</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>205</td>
                                    <td>550</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prod100R61teHose;