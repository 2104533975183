
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import compactCylinderHose from '../../../../assets/images/product/special-hoses/compactcylinder-hose.jpeg';


function CompactCylinderHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="compactcylinder-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>compactcylinder hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={compactCylinderHose} alt='compactcylinder hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>EN8572SC/ISO11237-2SC</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> Two high tensile carbon steel wire braid for 3/4" & 1-wire braid for 1.0".<br />
                                            <strong>Cover:</strong> Oil, weather, abrasion resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>Forhydraulicfluids: -40 ℃ to + 100 ℃ / -40℉ to + 212℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Hydraulic system medium-high pressure lines.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                            <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>HWM G19</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>25.6</td>
                                    <td>27.8</td>
                                    <td>215</td>
                                    <td>3120</td>
                                    <td>860</td>
                                    <td>12480</td>
                                    <td>230</td>
                                    <td>850</td>
                                </tr>
                                <tr>
                                    <td>HWM G25</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>31.3</td>
                                    <td>33.6</td>
                                    <td>90</td>
                                    <td>1300</td>
                                    <td>360</td>
                                    <td>5200</td>
                                    <td>270</td>
                                    <td>800</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompactCylinderHose;