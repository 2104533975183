import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';


function IndustrialMenu(props) {

    const linkToActive = props.activeLink;
    const activeLinkClass = (menuName) => {
        let matchWith = '';
        if (menuName === linkToActive) {
            return 'active-menu'
        }
        return matchWith;
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, [linkToActive]);

    return (
        <div className='display-product-menu-wrapper'>
            <h3 className='product-menu-title'>Industrial Hoses</h3>
            <div className='product-menu-list'>
                <div className={"product-menu-item " + activeLinkClass('air-water-hose')}>
                    <NavLink to="/products/industrial/air-water-hose">air water hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('air-pneumatic-tool-hose')}>
                    <NavLink to="/products/industrial/air-pneumatic-tool-hose">air / pneumatic tool hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('carbon-free-hose')}>
                    <NavLink to="/products/industrial/carbon-free-hose">carbon free hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('rock-drill-hose')}>
                    <NavLink to="/products/industrial/rock-drill-hose">rock drill hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('super-rock-drill-hose')}>
                    <NavLink to="/products/industrial/super-rock-drill-hose">super rock drill hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('steam-hose')}>
                    <NavLink to="/products/industrial/steam-hose">steam hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('super-steam-hose')}>
                    <NavLink to="/products/industrial/super-steam-hose">super steam hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('sand-blast-hose')}>
                    <NavLink to="/products/industrial/sand-blast-hose">sand blast hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('super-sand-blast-hose')}>
                    <NavLink to="/products/industrial/super-sand-blast-hose">super sand blast hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('super-air-hose')}>
                    <NavLink to="/products/industrial/super-air-hose">super air hose</NavLink>
                </div>
            </div>
        </div>
    );
}

export default IndustrialMenu;