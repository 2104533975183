import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import industrialHosesImg from '../../assets/images/home/industrial-hoses.jpeg';
import hydraulicHosesImg from '../../assets/images/home/hydraulic-hoses.jpeg';
import spiralHosesImg from '../../assets/images/home/spiral-hoses.jpeg';
import specialHosesImg from '../../assets/images/home/special-hoses.jpeg';
import dredgeHosesImg from '../../assets/images/home/dredge-hoses.jpeg';

import './Home.scss';


function Home() {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    return (
        <div className="home-wrapper">
            <div className="hero-wrapper">
                <div className="hero-text-wrapper app-container">
                    <h4 className="title">
                        Trusted dredge hoses
                    </h4>
                    <h1 className="subtitle">
                        Trusted & Reliable Dredge Hoses And Industrial Hoses
                    </h1>
                    <p className="summary">
                    </p>
                </div>
            </div>
            {/* product */}
            <div className='pro-wrp-uppr'>
                <div className='product-wrapper'>
                    <div className='product'>
                        <div className='product-title'>
                            <h2>industrial hoses</h2>
                        </div>
                        <div className='product-img'>
                            <img src={industrialHosesImg} alt='industrial hoses' />
                        </div>
                        <div className='product-info'>
                            <ul>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/industrial/air-water-hose">air water hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/industrial/air-pneumatic-tool-hose">air / pneumatic tool hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/industrial/carbon-free-hose">carbon free hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/industrial/rock-drill-hose">rock drill hose</NavLink>
                                </li>
                            </ul>

                            <div className='learn-more-btn'>
                                <NavLink to="/products"><button className='app-button'>Learn More</button></NavLink>
                            </div>
                        </div>
                    </div>

                    <div className='product'>
                        <div className='product-title'>
                            <h2>hydraulic hoses</h2>
                        </div>
                        <div className='product-img'>
                            <img src={hydraulicHosesImg} alt='hydraulic hoses' />
                        </div>
                        <div className='product-info'>

                            <ul>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/hydraulic/100-r1-at-1sn-hose">100 r1 at /1sn hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/hydraulic/100-r1-st-hose(thicker-cover)">100 r1 st hose(thicker cover)</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/hydraulic/100-r1-high-temperature-hose">100 r1 high temperature hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/hydraulic/100-r2-at-2sn-hose">100 r2 at /2sn hose</NavLink>
                                </li>
                            </ul>

                            <div className='learn-more-btn'>
                                <NavLink to="/products"><button className='app-button'>Learn More</button></NavLink>
                            </div>
                        </div>
                    </div>

                    <div className='product'>
                        <div className='product-title'>
                            <h2>spiral hoses</h2>
                        </div>
                        <div className='product-img'>
                            <img src={spiralHosesImg} alt='spiral hoses' />
                        </div>
                        <div className='product-info'>

                            <ul>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/spiral/hipulse-4sp">hipulse 4sp</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/spiral/hipulse-4sh">hipulse 4sh</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/spiral/hipulse-r12">hipulse r12</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/spiral/hipulse-r13">hipulse r13</NavLink>
                                </li>
                            </ul>

                            <div className='learn-more-btn'>
                                <NavLink to="/products"><button className='app-button'>Learn More</button></NavLink>
                            </div>
                        </div>
                    </div>

                    <div className='product'>
                        <div className='product-title'>
                            <h2>special hoses</h2>
                        </div>
                        <div className='product-img'>
                            <img src={specialHosesImg} alt='special hoses' />
                        </div>
                        <div className='product-info'>

                            <ul>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/special/compact-1sc-hose">compact-1sc hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/special/compact-2sc-hose">compact-2sc hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/special/compactcylinder-hose">compactcylinder hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/special/1and2-wireagro-hose">1and2_wireagro hose</NavLink>
                                </li>
                            </ul>

                            <div className='learn-more-btn'>
                                <NavLink to="/products"><button className='app-button'>Learn More</button></NavLink>
                            </div>
                        </div>
                    </div>

                    <div className='product'>
                        <div className='product-title'>
                            <h2>dredge hoses</h2>
                        </div>
                        <div className='product-img'>
                            <img src={dredgeHosesImg} alt='dredge hoses' />
                        </div>
                        <div className='product-info'>

                            <ul>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/dredge/discharge-dredging-hose">discharge dredging hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/dredge/suction-dredging-hose">suction dredging hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/dredge/floating-hose">floating hose</NavLink>
                                </li>
                                <li>
                                    <span>
                                        <svg className='note-icon' version="1.1" viewBox="0 0 512.019 512.019" x="0px" y="0px" id="svg-b379" >
                                            <path fill="currentColor" d="M362.676,0H149.343C119.888,0,96.01,23.878,96.01,53.333v448 c-0.011,5.891,4.757,10.675,10.648,10.686c2.84,0.005,5.565-1.123,7.571-3.134L256.01,367.083l141.781,141.781 c4.16,4.171,10.914,4.179,15.085,0.019c2.006-2.001,3.133-4.717,3.134-7.55v-448C416.01,23.878,392.131,0,362.676,0z">
                                            </path>
                                        </svg>
                                    </span><NavLink to="/products/dredge/expansion-hose">expansion hose</NavLink>
                                </li>
                            </ul>

                            <div className='learn-more-btn'>
                                <NavLink to="/products"><button className='app-button'>Learn More</button></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;