
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import compact1scHose from '../../../../assets/images/product/special-hoses/compact-1sc-hose.jpeg';


function Compact1scHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="compact-1sc-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>compact-1sc hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={compact1scHose} alt='compact 1sc hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>EN857 1SC / ISO 11237-1SC</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> One high tensile carbon steel wire braid.<br />
                                            <strong>Cover:</strong> Oil, weather, abrasion ozone and heat resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-40℃ to + 100℃ / -40℉ to + 212℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Hydraulic system low-medium pressure lines with narrow installations, return & suction lines.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>HWS C06MF</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>10.4</td>
                                    <td>12.6</td>
                                    <td>225</td>
                                    <td>3263</td>
                                    <td>900</td>
                                    <td>13050</td>
                                    <td>50</td>
                                    <td>195</td>
                                </tr>
                                <tr>
                                    <td>HWS C08MF</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>11.8</td>
                                    <td>14.0</td>
                                    <td>215</td>
                                    <td>3118</td>
                                    <td>860</td>
                                    <td>12470</td>
                                    <td>55</td>
                                    <td>220</td>
                                </tr>
                                <tr>
                                    <td>HWS C10MF</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>13.8</td>
                                    <td>16.3</td>
                                    <td>180</td>
                                    <td>2610</td>
                                    <td>720</td>
                                    <td>10440</td>
                                    <td>65</td>
                                    <td>280</td>
                                </tr>
                                <tr>
                                    <td>HWS C12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>17.0</td>
                                    <td>19.4</td>
                                    <td>160</td>
                                    <td>2320</td>
                                    <td>640</td>
                                    <td>9280</td>
                                    <td>90</td>
                                    <td>350</td>
                                </tr>
                                <tr>
                                    <td>HWS C16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>20.2</td>
                                    <td>22.6</td>
                                    <td>130</td>
                                    <td>1885</td>
                                    <td>520</td>
                                    <td>7540</td>
                                    <td>100</td>
                                    <td>460</td>
                                </tr>
                                <tr>
                                    <td>HWS C19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>23.8</td>
                                    <td>26.2</td>
                                    <td>105</td>
                                    <td>1523</td>
                                    <td>420</td>
                                    <td>6090</td>
                                    <td>120</td>
                                    <td>560</td>
                                </tr>
                                <tr>
                                    <td>HWS C25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>31.3</td>
                                    <td>33.7</td>
                                    <td>90</td>
                                    <td>1305</td>
                                    <td>360</td>
                                    <td>5220</td>
                                    <td>150</td>
                                    <td>830</td>
                                </tr>
                                <tr>
                                    <td>HWS C31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>38.4</td>
                                    <td>41.0</td>
                                    <td>65</td>
                                    <td>943</td>
                                    <td>260</td>
                                    <td>3770</td>
                                    <td>210</td>
                                    <td>1060</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Compact1scHose;