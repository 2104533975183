import '../../ProductDisplay.scss';
import IndustrialMenu from '../../product-menu/industrial-menu/IndustrialMenu';

import airWaterHose from '../../../../assets/images/product/industrial-hoses/air-water-hose.jpeg';


function AirWaterHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <IndustrialMenu activeLink="air-water-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>industrial hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>air water hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={airWaterHose} alt='air water hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>IS 444: Type 2 and IS 446: Type 1 (Exceeds performance requirement)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Specially compounded nitrile and other synthetic rubber blend. RMA -Class C oil resistance.<br />
                                            <strong>Reinforcement:</strong> One high tenacity synthetic textile braid.<br />
                                            <strong>Cover:</strong> Weather, abrasion resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-30 ℃ to + 80 ℃ / -22℉ to + 176℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>All types of water delivery and air application in engineering, manufacturing, workshop, construction industry and irrigation.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>ITS RR06MF</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>-</td>
                                    <td>12.7</td>
                                    <td>12</td>
                                    <td>174</td>
                                    <td>48</td>
                                    <td>696</td>
                                    <td>65</td>
                                    <td>125</td>
                                </tr>
                                <tr>
                                    <td>ITS RR08MF</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>-</td>
                                    <td>14.7</td>
                                    <td>12</td>
                                    <td>174</td>
                                    <td>48</td>
                                    <td>696</td>
                                    <td>85</td>
                                    <td>165</td>
                                </tr>
                                <tr>
                                    <td>ITS RR10MF</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>16.3</td>
                                    <td>12</td>
                                    <td>174</td>
                                    <td>48</td>
                                    <td>696</td>
                                    <td>90</td>
                                    <td>200</td>
                                </tr>
                                <tr>
                                    <td>ITS RR12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>20.2</td>
                                    <td>12</td>
                                    <td>174</td>
                                    <td>48</td>
                                    <td>696</td>
                                    <td>115</td>
                                    <td>295</td>
                                </tr>
                                <tr>
                                    <td>ITS RR16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>23.1</td>
                                    <td>12</td>
                                    <td>174</td>
                                    <td>48</td>
                                    <td>696</td>
                                    <td>140</td>
                                    <td>330</td>
                                </tr>
                                <tr>
                                    <td>ITS RR19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>27.4</td>
                                    <td>12</td>
                                    <td>174</td>
                                    <td>48</td>
                                    <td>696</td>
                                    <td>152</td>
                                    <td>480</td>
                                </tr>
                                <tr>
                                    <td>ITS RR25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>34.4</td>
                                    <td>12</td>
                                    <td>174</td>
                                    <td>48</td>
                                    <td>696</td>
                                    <td>200</td>
                                    <td>685</td>
                                </tr>
                                <tr>
                                    <td>ITS RR31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>-</td>
                                    <td>42.8</td>
                                    <td>12</td>
                                    <td>174</td>
                                    <td>48</td>
                                    <td>696</td>
                                    <td>250</td>
                                    <td>1050</td>
                                </tr>
                                <tr>
                                    <td>ITS RR38MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>-</td>
                                    <td>49.0</td>
                                    <td>12</td>
                                    <td>174</td>
                                    <td>48</td>
                                    <td>696</td>
                                    <td>305</td>
                                    <td>1245</td>
                                </tr>
                                <tr>
                                    <td>ITS RR51MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>-</td>
                                    <td>63.5</td>
                                    <td>12</td>
                                    <td>174</td>
                                    <td>48</td>
                                    <td>696</td>
                                    <td>355</td>
                                    <td>1670</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AirWaterHose;