
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import prod1and2WireAgroHose from '../../../../assets/images/product/special-hoses/1and2-wireagro-hose.jpeg';


function Prod1and2WireAgroHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="1and2-wireagro-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>1and2_wireagro hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={prod1and2WireAgroHose} alt='1and2 wireagro hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>KKN Trading-Proprietary</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> High tensile carbon steel wire braid.<br />
                                            <strong>Cover:</strong> Oil, weather, abrasion, ozone and heat resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>Forhydraulicfluids: -40 ℃ to + 100 ℃ / -40℉ to + 212℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Hydraulically lifting trolley of tractors for unloading and other agricultural application.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>HWM P10</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>17.4</td>
                                    <td>140</td>
                                    <td>2030</td>
                                    <td>560</td>
                                    <td>8120</td>
                                    <td>110</td>
                                    <td>330</td>
                                </tr>
                                <tr>
                                    <td>HWM P12</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>20.4</td>
                                    <td>140</td>
                                    <td>2030</td>
                                    <td>560</td>
                                    <td>8120</td>
                                    <td>150</td>
                                    <td>400</td>
                                </tr>
                                <tr>
                                    <th colSpan={13} style={{ textAlign: 'left', paddingTop: '12px', paddingBottom: '6px' }}>For 2-Wire</th>
                                </tr>
                                <tr>
                                    <td>HWM R10</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>18.6</td>
                                    <td>140</td>
                                    <td>2030</td>
                                    <td>560</td>
                                    <td>8120</td>
                                    <td>110</td>
                                    <td>380</td>
                                </tr>
                                <tr>
                                    <td>HWM R12</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>22.0</td>
                                    <td>140</td>
                                    <td>2030</td>
                                    <td>560</td>
                                    <td>8120</td>
                                    <td>150</td>
                                    <td>470</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prod1and2WireAgroHose;