import { Route, Routes } from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import './App.scss';
import Home from './pages/home/Home';
import About from './pages/about/About';
import ContactUs from './pages/contact-us/ContactUs';
import Products from './pages/products/Products';
import EnquireUs from './pages/enquire-us/EnquireUs';

import AirWaterHose from './pages/products/industrial-hoses/air-water-hose/AirWaterHose';
import AirPneumaticToolHose from './pages/products/industrial-hoses/air-pneumatic-tool-hose/AirPneumaticToolHose';
import CarbonFreeHose from './pages/products/industrial-hoses/carbon-free-hose/CarbonFreeHose';
import RockDrillHose from './pages/products/industrial-hoses/rock-drill-hose/RockDrillHose';
import SuperRockDrillHose from './pages/products/industrial-hoses/super-rock-drill-hose/SuperRockDrillHose';
import SteamHose from './pages/products/industrial-hoses/steam-hose/SteamHose';
import SuperSteamHose from './pages/products/industrial-hoses/super-steam-hose/SuperSteamHose';
import SandBlastHose from './pages/products/industrial-hoses/sand-blast-hose/SandBlastHose';
import SuperSandBlastHose from './pages/products/industrial-hoses/super-sand-blast-hose/SuperSandBlastHose';
import SuperAirHose from './pages/products/industrial-hoses/super-air-hose/SuperAirHose';

import Prod100R1at1snHose from './pages/products/hydraulic-hoses/100-r1-at-1sn-hose/Prod100R1at1snHose';
import Prod100R1stHoseThickerCover from './pages/products/hydraulic-hoses/100-r1-st-hose(thicker-cover)/Prod100R1stHoseThickerCover';
import Prod100R1highTemperatureHose from './pages/products/hydraulic-hoses/100-r1-high-temperature-hose/Prod100R1highTemperatureHose';
import Prod100R2at2snHose from './pages/products/hydraulic-hoses/100-r2-at-2sn-hose/Prod100R2at2snHose';
import Prod100R2stHoseThickerCover from './pages/products/hydraulic-hoses/100-r2-st-hose(thicker-cover)/Prod100R2stHoseThickerCover';
import Prod100R2highTemperatureHose from './pages/products/hydraulic-hoses/100-r2-high-temperature-hose/Prod100R2highTemperatureHose';
import Prod100R5rHose from './pages/products/hydraulic-hoses/100-r5-r-hose/Prod100R5rHose';
import Prod100R61teHose from './pages/products/hydraulic-hoses/100-r6-1te-hose/Prod100R61teHose';
import Prod100R6highTemperatureHose from './pages/products/hydraulic-hoses/100-r6-high-temperature-hose/Prod100R6highTemperatureHose';
import Prod100R32teHose from './pages/products/hydraulic-hoses/100-r3-2te-hose/Prod100R32teHose';

import Hipulse4sp from './pages/products/spiral-hoses/hipulse-4sp/Hipulse4sp';
import Hipulse4sh from './pages/products/spiral-hoses/hipulse-4sh/Hipulse4sh';
import Hipulser12 from './pages/products/spiral-hoses/hipulse-r12/Hipulser12';
import Hipulser13 from './pages/products/spiral-hoses/hipulse-r13/Hipulser13';
import Hipulser15 from './pages/products/spiral-hoses/hipulse-r15/Hipulser15';

import Compact1scHose from './pages/products/special-hoses/compact-1sc-hose/Compact1scHose';
import Compact2scHose from './pages/products/special-hoses/compact-2sc-hose/Compact2scHose';
import CompactCylinderHose from './pages/products/special-hoses/compactcylinder-hose/CompactCylinderHose';
import Prod1and2WireAgroHose from './pages/products/special-hoses/1and2-wireagro-hose/Prod1and2WireAgroHose';
import JackHose from './pages/products/special-hoses/jack-hose/JackHose';
import JetWashR1Hose from './pages/products/special-hoses/jet-wash-r1-hose/JetWashR1Hose';
import JetWashR2Hose from './pages/products/special-hoses/jet-wash-r2-hose/JetWashR2Hose';
import JetWashCompact1scHose from './pages/products/special-hoses/jet-wash-compact-1sc-hose/JetWashCompact1scHose';
import JetWashCompact2scHose from './pages/products/special-hoses/jet-wash-compact-2sc-hose/JetWashCompact2scHose';
import JetWash3000PsiHose from './pages/products/special-hoses/jet-wash-3000-psi-hose/JetWash3000PsiHose';
import JetWash4000PsiHose from './pages/products/special-hoses/jet-wash-4000-psi-hose/JetWash4000PsiHose';
import JetWash5000PsiHose from './pages/products/special-hoses/jet-wash-5000-psi-hose/JetWash5000PsiHose';
import PetselDispensingHose from './pages/products/special-hoses/petsel-dispensing-hose/PetselDispensingHose';
import Prod1WireLpgHose from './pages/products/special-hoses/1-wire-lpg-hose/Prod1WireLpgHose';
import Prod2textileLpgHose from './pages/products/special-hoses/2textile-lpg-hose/Prod2textileLpgHose';
import FuelDispensingHose from './pages/products/special-hoses/fuel-dispensing-hose/FuelDispensingHose';
import MultiWashHose from './pages/products/special-hoses/multiwash-hose/MultiWashHose';
import SuperSprayHose from './pages/products/special-hoses/super-spray-hose/SuperSprayHose';
import ChemicalHose from './pages/products/special-hoses/chemical-hose/ChemicalHose';
import RigHose from './pages/products/special-hoses/rig-hose/RigHose';
import RigHoseHt from './pages/products/special-hoses/righose-ht/RigHoseHt';

import AutomotiveAirBrakeHose from './pages/products/special-hoses/automotive-air-brake-hose/AutomotiveAirBrakeHose';
import DischargeDredgingHose from './pages/products/dredge-hoses/discharge-dredging-hose/DischargeDredgingHose';
import SuctionDredgingHose from './pages/products/dredge-hoses/suction-dredging-hose/SuctionDredgingHose';
import FloatingHose from './pages/products/dredge-hoses/floating-hose/FloatingHose';
import ExpansionHose from './pages/products/dredge-hoses/expansion-hose/ExpansionHose';

import NotFound404 from './components/404-not-found/NotFound404';


function App() {
  return (
    <div className="app-wrapper">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/enquire-us" element={<EnquireUs />} />

        <Route path="/products/industrial/air-water-hose" element={<AirWaterHose />} />
        <Route path="/products/industrial/air-pneumatic-tool-hose" element={<AirPneumaticToolHose />} />
        <Route path="/products/industrial/carbon-free-hose" element={<CarbonFreeHose />} />
        <Route path="/products/industrial/rock-drill-hose" element={<RockDrillHose />} />
        <Route path="/products/industrial/super-rock-drill-hose" element={<SuperRockDrillHose />} />
        <Route path="/products/industrial/steam-hose" element={<SteamHose />} />
        <Route path="/products/industrial/super-steam-hose" element={<SuperSteamHose />} />
        <Route path="/products/industrial/sand-blast-hose" element={<SandBlastHose />} />
        <Route path="/products/industrial/super-sand-blast-hose" element={<SuperSandBlastHose />} />
        <Route path="/products/industrial/super-air-hose" element={<SuperAirHose />} />

        <Route path="/products/hydraulic/100-r1-at-1sn-hose" element={<Prod100R1at1snHose />} />
        <Route path="/products/hydraulic/100-r1-st-hose(thicker-cover)" element={<Prod100R1stHoseThickerCover />} />
        <Route path="/products/hydraulic/100-r1-high-temperature-hose" element={<Prod100R1highTemperatureHose />} />
        <Route path="/products/hydraulic/100-r2-at-2sn-hose" element={<Prod100R2at2snHose />} />
        <Route path="/products/hydraulic/100-r2-st-hose(thicker-cover)" element={<Prod100R2stHoseThickerCover />} />
        <Route path="/products/hydraulic/100-r2-high-temperature-hose" element={<Prod100R2highTemperatureHose />} />
        <Route path="/products/hydraulic/100-r5-r-hose" element={<Prod100R5rHose />} />
        <Route path="/products/hydraulic/100-r6-1te-hose" element={<Prod100R61teHose />} />
        <Route path="/products/hydraulic/100-r6-high-temperature-hose" element={<Prod100R6highTemperatureHose />} />
        <Route path="/products/hydraulic/100-r3-2te-hose" element={<Prod100R32teHose />} />

        <Route path="/products/spiral/hipulse-4sp" element={<Hipulse4sp />} />
        <Route path="/products/spiral/hipulse-4sh" element={<Hipulse4sh />} />
        <Route path="/products/spiral/hipulse-r12" element={<Hipulser12 />} />
        <Route path="/products/spiral/hipulse-r13" element={<Hipulser13 />} />
        <Route path="/products/spiral/hipulse-r15" element={<Hipulser15 />} />

        <Route path="/products/special/compact-1sc-hose" element={<Compact1scHose />} />
        <Route path="/products/special/compact-2sc-hose" element={<Compact2scHose />} />
        <Route path="/products/special/compactcylinder-hose" element={<CompactCylinderHose />} />
        <Route path="/products/special/1and2-wireagro-hose" element={<Prod1and2WireAgroHose />} />
        <Route path="/products/special/jack-hose" element={<JackHose />} />
        <Route path="/products/special/jet-wash-r1-hose" element={<JetWashR1Hose />} />
        <Route path="/products/special/jet-wash-r2-hose" element={<JetWashR2Hose />} />
        <Route path="/products/special/jet-wash-compact-1sc-hose" element={<JetWashCompact1scHose />} />
        <Route path="/products/special/jet-wash-compact-2sc-hose" element={<JetWashCompact2scHose />} />
        <Route path="/products/special/jet-wash-3000-psi-hose" element={<JetWash3000PsiHose />} />
        <Route path="/products/special/jet-wash-4000-psi-hose" element={<JetWash4000PsiHose />} />
        <Route path="/products/special/jet-wash-5000-psi-hose" element={<JetWash5000PsiHose />} />
        <Route path="/products/special/petsel-dispensing-hose" element={<PetselDispensingHose />} />
        <Route path="/products/special/1-wire-lpg-hose" element={<Prod1WireLpgHose />} />
        <Route path="/products/special/2textile-lpg-hose" element={<Prod2textileLpgHose />} />
        <Route path="/products/special/fuel-dispensing-hose" element={<FuelDispensingHose />} />
        <Route path="/products/special/multiwash-hose" element={<MultiWashHose />} />
        <Route path="/products/special/super-spray-hose" element={<SuperSprayHose />} />
        <Route path="/products/special/chemical-hose" element={<ChemicalHose />} />
        <Route path="/products/special/rig-hose" element={<RigHose />} />
        <Route path="/products/special/righose-ht" element={<RigHoseHt />} />
        <Route path="/products/special/automotive-air-brake-hose" element={<AutomotiveAirBrakeHose />} />

        <Route path="/products/dredge/discharge-dredging-hose" element={<DischargeDredgingHose />} />
        <Route path="/products/dredge/suction-dredging-hose" element={<SuctionDredgingHose />} />
        <Route path="/products/dredge/floating-hose" element={<FloatingHose />} />
        <Route path="/products/dredge/expansion-hose" element={<ExpansionHose />} />

        <Route path="*" exact={true} element={<NotFound404 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;