import './NotFound404.scss';


function NotFound404() {
  return (
    <div className="page-not-found-wrapper">
      <h1>404 Page not found...!</h1>
    </div>
  );
}

export default NotFound404;
