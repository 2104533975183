import React, { useEffect, useState } from 'react';
import './EnquireUs.scss';

function EnquireUs() {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isErrorOnFromSubmit, setIsErrorOnFromSubmit] = useState(false);

    const validateForm = () => {
        setName(name.trim());
        setEmail(email.trim());
        setSubject(subject.trim());
        setMessage(message.trim());

        let errors = {};
        if (!name) {
            errors.name = "Name is required";
        }
        if (!email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Email address is invalid";
        }
        if (!number) {
            errors.number = "Mobile Number is required";
        } else if (number.length < 10) {
            errors.number = "Invalid Mobile Number";
        }
        if (!subject) {
            errors.subject = "Subject is required";
        }
        if (!message) {
            errors.message = "Message is required";
        }
        return errors;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isFormSubmitted) {
            return;
        }
        const errors = validateForm();

        // If there are errors, set the state with the error object
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {

            const submitEnquireUs = async () => {
                try {
                    console.log(process.env.REACT_APP_BASE_URL)
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/enquire-us`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ name, email, number, subject, message })
                    });

                    if (response.ok && response.status === 201) {
                        setName("");
                        setEmail("");
                        setNumber("");
                        setSubject("");
                        setMessage("");
                        setErrors({});
                        setIsFormSubmitted(true);
                        setTimeout(() => {
                            setIsFormSubmitted(false);
                        }, 5000);
                    } else if (!response.ok && response.status === 400) {
                        console.log(response);
                        setIsErrorOnFromSubmit(true);
                        setTimeout(() => {
                            setIsErrorOnFromSubmit(false);
                        }, 10000);
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            submitEnquireUs();
        }
    }

    return (
        <div className='enquire-us-section'>
            <div className='enquire-us-wrapper'>
                <h1 className='heading'>enquire us</h1>
                <div className='enquire-us-form-wrapper '>
                    <form className='enquire-us-form' onSubmit={handleSubmit}>
                        <div className={"name " + ((errors.name && !name) ? "invalid" : "")}>
                            <input type='text' name='name' placeholder='Your name*' value={name} onChange={(event) => setName(event.target.value)} />
                        </div>
                        <div className={"email " + ((errors.email && !email) ? "invalid" : "")}>
                            <input type='email' name='email' placeholder='Email*' value={email} onChange={(event) => setEmail(event.target.value)} />
                        </div>
                        <div className={"number " + ((errors.number) ? "invalid" : "")}>
                            <input type='number' name='number' placeholder='Phone number*' value={number} onChange={(event) => event.target.value.length <= 10 && setNumber(event.target.value)} />
                        </div>
                        <div className={"subject " + ((errors.subject && !subject) ? "invalid" : "")}>
                            <input type='text' name='subject' placeholder='Subject*' value={subject} onChange={(event) => setSubject(event.target.value)} />
                        </div>
                        <div className={"message " + ((errors.message && !message) ? "invalid" : "")}>
                            <textarea type='text' rows="8" name='message' placeholder='Message*' value={message} onChange={(event) => setMessage(event.target.value)} />
                        </div>
                        <div className='send-msg-btn'>
                            <button >Submit</button>
                        </div>
                    </form>

                    <h3 id='formSubmittedMessage' className={"form-submitted " + (isFormSubmitted ? "submitted" : "")}>Your enquiry sent successfully.</h3>
                    <h3 id='formErrorMessage' className={"form-not-submit " + (isErrorOnFromSubmit ? "not-submit" : "")}>Your enquiry not sent, Please try agin alter.</h3>
                </div>
            </div>
        </div>
    );
}

export default EnquireUs;
