
import '../../ProductDisplay.scss';
import DredgeMenu from '../../product-menu/dredge-menu/DredgeMenu';

import floatingHose from '../../../../assets/images/product/dredge-hoses/floating-hose.jpeg';


function FloatingHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <DredgeMenu activeLink="floating-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>dredge hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>floating hose</h3>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Construction:</strong></td>
                                <td>
                                    <strong>Tube:</strong> Good wear resistance, flexibility and anti-scratch ability.<br />
                                    <strong>Cover:</strong> Outer cover with excellent abrasion resistance and UV protection.
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-20 ℃ to + 50 ℃</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Dredging floating hose is equipped with PE foam body, adjusted to the buoyance of the dredged material, usually used with dredgers for silt/gravel conveyance. Besides, it has good flexibility for the most challenging working environments. It can withstand wind, and waves and is suitable for the harshest sea or mining conditions.</td>
                            </tr>
                        </tbody>
                    </table>

                    <img src={floatingHose} alt='floating hose'></img>

                </div>
            </div>
        </div>
    );
}

export default FloatingHose;