
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import compact2scHose from '../../../../assets/images/product/special-hoses/compact-2sc-hose.png';


function Compact2scHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="compact-2sc-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>compact-2sc hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={compact2scHose} alt='compact 2sc hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>EN857 2SC / ISO 11237-2SC</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> Two high tensile carbon steel wire braid.<br />
                                            <strong>Cover:</strong> Oil, weather, abrasion, ozone and heat resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-40℃ to + 100℃ / -40℉ to + 212℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Hydraulic system high pressure lines with narrow installations, return & suction lines.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                            <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>HWS D06MF</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>11.4</td>
                                    <td>13.6</td>
                                    <td>400</td>
                                    <td>5800</td>
                                    <td>1600</td>
                                    <td>23200</td>
                                    <td>50</td>
                                    <td>285</td>
                                </tr>
                                <tr>
                                    <td>HWS D08MF</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>13.0</td>
                                    <td>15.2</td>
                                    <td>350</td>
                                    <td>5075</td>
                                    <td>1400</td>
                                    <td>20300</td>
                                    <td>55</td>
                                    <td>330</td>
                                </tr>
                                <tr>
                                    <td>HWS D10MF</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>15.0</td>
                                    <td>17.3</td>
                                    <td>330</td>
                                    <td>4785</td>
                                    <td>1320</td>
                                    <td>19140</td>
                                    <td>65</td>
                                    <td>400</td>
                                </tr>
                                <tr>
                                    <td>HWS D12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>18.5</td>
                                    <td>20.8</td>
                                    <td>275</td>
                                    <td>3988</td>
                                    <td>1100</td>
                                    <td>15950</td>
                                    <td>90</td>
                                    <td>510</td>
                                </tr>
                                <tr>
                                    <td>HWS D16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>21.8</td>
                                    <td>24.2</td>
                                    <td>250</td>
                                    <td>3625</td>
                                    <td>1000</td>
                                    <td>14500</td>
                                    <td>100</td>
                                    <td>680</td>
                                </tr>
                                <tr>
                                    <td>HWS D19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>25.6</td>
                                    <td>28</td>
                                    <td>215</td>
                                    <td>3118</td>
                                    <td>860</td>
                                    <td>12470</td>
                                    <td>120</td>
                                    <td>835</td>
                                </tr>
                                <tr>
                                    <td>HWS D25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>33.4</td>
                                    <td>35.8</td>
                                    <td>165</td>
                                    <td>2393</td>
                                    <td>660</td>
                                    <td>9570</td>
                                    <td>150</td>
                                    <td>1210</td>
                                </tr>
                                <tr>
                                    <td>HWS D31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>41.0</td>
                                    <td>43.6</td>
                                    <td>125</td>
                                    <td>1813</td>
                                    <td>500</td>
                                    <td>7250</td>
                                    <td>210</td>
                                    <td>1620</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Compact2scHose;