
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import prod1WireLpgHose from '../../../../assets/images/product/special-hoses/1-wire-lpg-hose.jpeg';


function Prod1WireLpgHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="1-wire-lpg-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>1-wire lpg hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={prod1WireLpgHose} alt='1 wire lpg hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>IS 9573-Type 1 / BS 4089-Type 1</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> LPG resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> One high tensile carbon steel wire braid<br />
                                            <strong>Cover:</strong> Black synthetic rubber blend. <strong>Pin pricked.</strong><br />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-30℃ to + 65℃ / -22℉ to + 149℉
                                    <br/>
                                    <strong className='text-danger'>Hose is capable of the rating. But LP gas should never be elevated above 38 ℃ / 100℉</strong>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Transfer of LPG in liquid or vapour from which is unvented between operations (permanently filled with liquid or vapour) including automotive.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>IWS 1KK 12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>23.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>150</td>
                                    <td>460</td>
                                </tr>
                                <tr>
                                    <td>IWS 1KK 16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>26.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>185</td>
                                    <td>550</td>
                                </tr>
                                <tr>
                                    <td>IWS 1KK 19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>31.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>225</td>
                                    <td>750</td>
                                </tr>
                                <tr>
                                    <td>IWS 1KK 25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>38.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>300</td>
                                    <td>1000</td>
                                </tr>
                                <tr>
                                    <td>IWS 1KK 31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>-</td>
                                    <td>46.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>380</td>
                                    <td>1350</td>
                                </tr>
                                <tr>
                                    <td>IWS 1KK38MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>-</td>
                                    <td>52.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>450</td>
                                    <td>1550</td>
                                </tr>
                                <tr>
                                    <td>IWS 1KK 51MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>-</td>
                                    <td>67.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>600</td>
                                    <td>2320</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prod1WireLpgHose;