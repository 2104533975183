import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';


function HydraulicMenu(props) {

    const linkToActive = props.activeLink;
    const activeLinkClass = (menuName) => {
        let matchWith = '';
        if (menuName === linkToActive) {
            return 'active-menu'
        }
        return matchWith;
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, [linkToActive]);

    return (
        <div className='display-product-menu-wrapper'>
            <h3 className='product-menu-title'>Industrial Hoses</h3>
            <div className='product-menu-list'>
                <div className={"product-menu-item " + activeLinkClass('100-r1-at-1sn-hose')}>
                    <NavLink to="/products/hydraulic/100-r1-at-1sn-hose">100 r1 at / 1sn hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('100-r1-st-hose(thicker-cover)')}>
                    <NavLink to="/products/hydraulic/100-r1-st-hose(thicker-cover)">100 r1 st hose(thicker cover)</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('100-r1-high-temperature-hose')}>
                    <NavLink to="/products/hydraulic/100-r1-high-temperature-hose">100 r1 high temperature hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('100-r2-at-2sn-hose')}>
                    <NavLink to="/products/hydraulic/100-r2-at-2sn-hose">100 r2 at / 2sn hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('100-r2-st-hose(thicker-cover)')}>
                    <NavLink to="/products/hydraulic/100-r2-st-hose(thicker-cover)">100 r2 st hose(thicker cover)</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('100-r2-high-temperature-hose')}>
                    <NavLink to="/products/hydraulic/100-r2-high-temperature-hose">100 r2 high temperature hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('100-r5-r-hose')}>
                    <NavLink to="/products/hydraulic/100-r5-r-hose">100 r5 r hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('100-r6-1te-hose')}>
                    <NavLink to="/products/hydraulic/100-r6-1te-hose">100 r6 / 1te hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('100-r6-high-temperature-hose')}>
                    <NavLink to="/products/hydraulic/100-r6-high-temperature-hose">100 r6 high temperature hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('100-r3-2te-hose')}>
                    <NavLink to="/products/hydraulic/100-r3-2te-hose">100 r3 / 2te hose</NavLink>
                </div>
            </div>
        </div>
    );
}

export default HydraulicMenu;