
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import fuelDispensingHose from '../../../../assets/images/product/special-hoses/fuel-dispensing-hose.png';


function FuelDispensingHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="fuel-dispensing-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>fuel dispensing hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={fuelDispensingHose} alt='fuel dispensing hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>IS 2396</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Black Oil & gasoline resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> Two high tenacity synthetic textile braid .Antistatic copper wire.<br />
                                            <strong>Cover:</strong> Oil, weather, abrasion, ozone and heat resistant synthetic rubber blend. Wrapped finish.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-30℃ to + 60℃ / -22℉ to + 140℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Dispensing of all type of gasoline /petrol and diesel in service stations and all types of dispensing pumps.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                            <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>ITS OO16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>26.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>100</td>
                                    <td>415</td>
                                </tr>
                                <tr>
                                    <td>ITS OO19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>32.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>150</td>
                                    <td>680</td>
                                </tr>
                                <tr>
                                    <td>ITS OO25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>38.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>68</td>
                                    <td>986</td>
                                    <td>210</td>
                                    <td>830</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FuelDispensingHose;