
import '../../ProductDisplay.scss';
import SpiralMenu from '../../product-menu/spiral-menu/SpiralMenu';

import hipulser13 from '../../../../assets/images/product/spiral-hoses/hipulse-r13.jpeg';


function Hipulser13() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpiralMenu activeLink="hipulse-r13" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>spiral hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>hipulse r13</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={hipulser13} alt='hipulse r13'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>EN 856 R13, ISO 3862-1 R13, SAE 100 R13</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> 4 Spiral Layer of High Tensile steel wire.<br />
                                            <strong>Cover:</strong> Oil, weather, abrasion resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>For hydraulic fluids: -40℃ to + 121℃ / -40℉ to + 250℉.
                                <br/>Exception: Air: max+ 70℃/158℉ and Water: max+ 85℃/ 185℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Heavy duty impulsive high pressure hydraulic applications like excavators, dosers, dumpers, cranes, compactors, rockbreakers, hydraulic drill rigs & other construction equipment's.</td>
                            </tr>
                            <tr>
                                <td><strong>Recommended fluids:</strong></td>
                                <td>Suitable for petroleum based hydraulic fluids, synthetic esters, biodegradable hydraulic fluids, water glycol based fluids & air.</td>
                            </tr>
                            <tr>
                                <td><strong>Features:</strong></td>
                                <td>Qualified for 1 million impulse cycle which is more than twice to relevant standard requirements. Good flexibility for compact hydraulic circuits. Good weather & ozone resistance.</td>
                            </tr>
                            <tr>
                                <td><strong>NOTE:</strong></td>
                                <td>Service temperature in excess of 100℃/212℉ and MBR lower than recommendation may materially reduce life of the hose.
                                    <br/>1.0 Mpa = 10bar, 1.0 Mpa = 145 psi, 1bar = ~1.0 kg /cm<span style={{ verticalAlign: "super",fontSize: "x-small"}}>2</span>
                                    <br/>Bend radius measured at inside of bend.</td>
                            </tr>
                        </tbody>
                    </table>


                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>MR13-12</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>31.8</td>
                                    <td>350</td>
                                    <td>5000</td>
                                    <td>1400</td>
                                    <td>20000</td>
                                    <td>240</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>MR13-10</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>38.4</td>
                                    <td>350</td>
                                    <td>5000</td>
                                    <td>1400</td>
                                    <td>20000</td>
                                    <td>300</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hipulser13;