import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';


function SpecialMenu(props) {

    const linkToActive = props.activeLink;
    const activeLinkClass = (menuName) => {
        let matchWith = '';
        if (menuName === linkToActive) {
            return 'active-menu'
        }
        return matchWith;
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, [linkToActive]);

    return (
        <div className='display-product-menu-wrapper'>
            <h3 className='product-menu-title'>Industrial Hoses</h3>
            <div className='product-menu-list'>
                <div className={"product-menu-item " + activeLinkClass('compact-1sc-hose')}>
                    <NavLink to="/products/special/compact-1sc-hose">compact-1sc hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('compact-2sc-hose')}>
                    <NavLink to="/products/special/compact-2sc-hose">compact-2sc hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('compactcylinder-hose')}>
                    <NavLink to="/products/special/compactcylinder-hose">compactcylinder hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('1and2-wireagro-hose')}>
                    <NavLink to="/products/special/1and2-wireagro-hose">1and2_wireagro hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('jack-hose')}>
                    <NavLink to="/products/special/jack-hose">jack hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('jet-wash-r1-hose')}>
                    <NavLink to="/products/special/jet-wash-r1-hose">jet wash-r1 hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('jet-wash-r2-hose')}>
                    <NavLink to="/products/special/jet-wash-r2-hose">jet wash-r2 hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('jet-wash-compact-1sc-hose')}>
                    <NavLink to="/products/special/jet-wash-compact-1sc-hose">jet wash compact-1sc hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('jet-wash-compact-2sc-hose')}>
                    <NavLink to="/products/special/jet-wash-compact-2sc-hose">jet wash-compact 2sc hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('jet-wash-3000-psi-hose')}>
                    <NavLink to="/products/special/jet-wash-3000-psi-hose">jet wash-3000 psi hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('jet-wash-4000-psi-hose')}>
                    <NavLink to="/products/special/jet-wash-4000-psi-hose">jet wash-4000 psi hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('jet-wash-5000-psi-hose')}>
                    <NavLink to="/products/special/jet-wash-5000-psi-hose">jet wash-5000 psi hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('petsel-dispensing-hose')}>
                    <NavLink to="/products/special/petsel-dispensing-hose">petsel-dispensing hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('1-wire-lpg-hose')}>
                    <NavLink to="/products/special/1-wire-lpg-hose">1-wire lpg hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('2textile-lpg-hose')}>
                    <NavLink to="/products/special/2textile-lpg-hose">2textile-lpg hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('fuel-dispensing-hose')}>
                    <NavLink to="/products/special/fuel-dispensing-hose">fuel-dispensing hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('multiwash-hose')}>
                    <NavLink to="/products/special/multiwash-hose">multiwash hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('super-spray-hose')}>
                    <NavLink to="/products/special/super-spray-hose">super spray hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('chemical-hose')}>
                    <NavLink to="/products/special/chemical-hose">chemical hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('rig-hose')}>
                    <NavLink to="/products/special/rig-hose">rig hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('righose-ht')}>
                    <NavLink to="/products/special/righose-ht">righose-ht</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('automotive-air-brake-hose')}>
                    <NavLink to="/products/special/automotive-air-brake-hose">automotive air brake hose</NavLink>
                </div>
            </div>
        </div>
    );
}

export default SpecialMenu;