
import '../../ProductDisplay.scss';
import DredgeMenu from '../../product-menu/dredge-menu/DredgeMenu';

import suctionDredgingHose from '../../../../assets/images/product/dredge-hoses/suction-dredging-hose.jpeg';


function SuctionDredgingHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <DredgeMenu activeLink="suction-dredging-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>dredge hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>suction dredging hose</h3>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Construction:</strong></td>
                                <td>
                                    <strong>Tube:</strong> Good wear resistance, flexibility and anti-scratch ability.<br />
                                    <strong>Cover:</strong> High impact resistance.
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-20 ℃ to + 50 ℃</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Suction dredging hose has the double properties of bearing both positive and negative pressure. It usually is installed between the dredger and pump and can work under negative pressure to absorb the mud. Meanwhile, the large diameter suction dredging hose is easy to be connected with the pipeline to reduce the oscillation caused by the waves to make sure smooth flowing in the pipeline.</td>
                            </tr>
                        </tbody>
                    </table>

                    <img src={suctionDredgingHose} alt='suction dredging hose'></img>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='dredge-hose-table'>
                            <caption><h3>dredge suction hose:</h3></caption>
                            <tbody>
                                <tr>
                                    <th>DIAMETER(NB)</th>
                                    <th>LENGTH(mm)</th>
                                    <th>PRESSURE KG/CM2</th>
                                    <th>VACUUM mmHg</th>
                                    <th>BEND ANGLE*</th>
                                </tr>
                                <tr>
                                    <td>300</td>
                                    <td>2500</td>
                                    <td>6</td>
                                    <td>90% of Full Vacuum</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>500</td>
                                    <td>2500</td>
                                    <td>6</td>
                                    <td>90% of Full Vacuum</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>650</td>
                                    <td>3000</td>
                                    <td>6</td>
                                    <td>90% of Full Vacuum</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>700</td>
                                    <td>2500</td>
                                    <td>6</td>
                                    <td>50% of Full Vacuum</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>800</td>
                                    <td>2000</td>
                                    <td>6</td>
                                    <td>50% of Full Vacuum</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>850</td>
                                    <td>2100</td>
                                    <td>6</td>
                                    <td>50% of Full Vacuum</td>
                                    <td>10</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuctionDredgingHose;