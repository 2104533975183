
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import jackHose from '../../../../assets/images/product/special-hoses/jack-hose.jpeg';


function JackHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="jack-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>jack hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={jackHose} alt='jack hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>IJ100 hydraulic jack hose specification for performance.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> Two high tensile carbon steel wire braid.<br />
                                            <strong>Cover:</strong> Oil, weather, abrasion, ozone and heat resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>Forhydraulicfluids: -40 ℃ to + 71 ℃ / -40℉ to + 160℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Hydraulically powered jacking system for lifting load in static condition. <br />
                                    <strong className='text-danger'>Strictly not for impulsive hydraulic application.</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                            <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>IWM J06</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>12.7</td>
                                    <td>15</td>
                                    <td>725</td>
                                    <td>10510</td>
                                    <td>1450</td>
                                    <td>42040</td>
                                    <td>100</td>
                                    <td>370</td>
                                </tr>
                                <tr>
                                    <td>IWM J10</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>16.7</td>
                                    <td>19</td>
                                    <td>725</td>
                                    <td>10510</td>
                                    <td>1450</td>
                                    <td>42040</td>
                                    <td>130</td>
                                    <td>530</td>
                                </tr>
                                <tr>
                                    <td>IWM J12</td>
                                    <td>3/8'</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>19.7</td>
                                    <td>22</td>
                                    <td>552</td>
                                    <td>8000</td>
                                    <td>1104</td>
                                    <td>32000</td>
                                    <td>200</td>
                                    <td>640</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JackHose;