import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import LOGO from '../../assets/images/company-logo.png';
import './Header.scss';


function Header() {

    // useEffect(() => {
    //     window.addEventListener("scroll", () => {
    //         const ele = document.getElementById("appHeader");
    //         if (window.scrollY) {
    //             if (ele && !ele.classList.contains('floating')) {
    //                 ele.classList.add('floating');
    //             }
    //         } else {
    //             ele.classList.remove('floating');
    //         }
    //     });
    // }, []);

    const [ariaExpanded, setAriaExpanded] = useState(false);
    const [dataVisible, setDataVisible] = useState(false);

    const changeData = () => {
        if (dataVisible === false) {
            setDataVisible(true);
            setAriaExpanded(true)
        } else if (dataVisible === true) {
            setDataVisible(false);
            setAriaExpanded(false);
        }
    }

    const clickOnMenuItem = () => {
        if (dataVisible === true) {
            setDataVisible(false);
            setAriaExpanded(false);
        }
    }

    return (
        <div className="header-wrapper app-container min-menu-color" id="appHeader">
            <div className="brand-wrapper">
                <NavLink to="">
                    <img src={LOGO} className="brand" alt="" />
                </NavLink>
            </div>
            <button onClick={changeData} id="mobile-nav-toggle" className="mobile-nav-toggle" aria-controls="menu-wrapper"
                aria-expanded={ariaExpanded}>
                <svg className="close-button" version="1.1" width="32" height="32" viewBox="0 0 20 20">
                    <path fill="#000000"
                        d="M10.707 10.5l5.646-5.646c0.195-0.195 0.195-0.512 0-0.707s-0.512-0.195-0.707 0l-5.646 5.646-5.646-5.646c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l5.646 5.646-5.646 5.646c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l5.646-5.646 5.646 5.646c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707l-5.646-5.646z">
                    </path>
                </svg>
                <svg className="menu-button" version="1.1" width="32" height="32" viewBox="0 0 20 20">
                    <path fill="#000000"
                        d="M17.5 6h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                    </path>
                    <path fill="#000000"
                        d="M17.5 11h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                    </path>
                    <path fill="#000000"
                        d="M17.5 16h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                    </path>
                </svg>
            </button>
            <ul className="menu-wrapper" data-visible={dataVisible}>
                <li className="menu-item" onClick={clickOnMenuItem}>
                    <NavLink to="/" className="menu-link">Home</NavLink>
                </li>
                <li className="menu-item" onClick={clickOnMenuItem}>
                    <NavLink to="products" className="menu-link">Products</NavLink>
                </li>
                <li className="menu-item" onClick={clickOnMenuItem}>
                    <NavLink to="about-us" className="menu-link">About us</NavLink>
                </li>
                <li className="menu-item" onClick={clickOnMenuItem}>
                    <NavLink to="contact-us" className="menu-link">Contact us</NavLink>
                </li>
                <li className="menu-item" onClick={clickOnMenuItem}>
                    <NavLink to="enquire-us" className="menu-link">Enquire Us</NavLink>
                </li>
            </ul>

            {/* <div className="appointment-wrapper">
                <NavLink to="enquire-us">
                    <button className="app-button">
                        Enquire Us
                    </button>
                </NavLink>
            </div> */}
        </div>
    );
}

export default Header;
