
import '../../ProductDisplay.scss';
import IndustrialMenu from '../../product-menu/industrial-menu/IndustrialMenu';

import superRockDrillHose from '../../../../assets/images/product/industrial-hoses/super-rock-drill-hose.jpeg';


function SuperRockDrillHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <IndustrialMenu activeLink="super-rock-drill-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>industrial hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>super rock drill hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={superRockDrillHose} alt='super rock drill hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>KKN Trading Proprietary. Exceeds performance requirement IS 446: Type 3 and ISO 2398-Type 3.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Specially compounded nitrile and other synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> One high tensile carbon steel wire braid.<br />
                                            <strong>Cover:</strong> Weather, abrasion and oil resistant synthetic rubber blend. (Pin pricked)
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-30 ℃ to + 80 ℃ / -22℉ to + 176℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>All types of high pressure heavy duty pneumatic drilling of ground, quaries etc. <strong className='text-danger'>Strictly not for high temperature air application.</strong></td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>IWS NN12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>22.4</td>
                                    <td>34.5</td>
                                    <td>500</td>
                                    <td>138</td>
                                    <td>2001</td>
                                    <td>125</td>
                                    <td>435</td>
                                </tr>
                                <tr>
                                    <td>IWS NN16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>25.6</td>
                                    <td>34.5</td>
                                    <td>500</td>
                                    <td>138</td>
                                    <td>2001</td>
                                    <td>140</td>
                                    <td>520</td>
                                </tr>
                                <tr>
                                    <td>IWS NN19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>29.0</td>
                                    <td>34.5</td>
                                    <td>500</td>
                                    <td>138</td>
                                    <td>2001</td>
                                    <td>200</td>
                                    <td>630</td>
                                </tr>
                                <tr>
                                    <td>IWS NN25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>35.6</td>
                                    <td>34.5</td>
                                    <td>500</td>
                                    <td>138</td>
                                    <td>2001</td>
                                    <td>250</td>
                                    <td>860</td>
                                </tr>
                                <tr>
                                    <td>IWS NN31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>-</td>
                                    <td>43.3</td>
                                    <td>34.5</td>
                                    <td>500</td>
                                    <td>138</td>
                                    <td>2001</td>
                                    <td>350</td>
                                    <td>1200</td>
                                </tr>
                                <tr>
                                    <td>IWS NN38MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>-</td>
                                    <td>49.5</td>
                                    <td>34.5</td>
                                    <td>500</td>
                                    <td>138</td>
                                    <td>2001</td>
                                    <td>380</td>
                                    <td>1390</td>
                                </tr>
                                <tr>
                                    <td>IWS NN51MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>-</td>
                                    <td>63.5</td>
                                    <td>34.5</td>
                                    <td>500</td>
                                    <td>138</td>
                                    <td>2001</td>
                                    <td>500</td>
                                    <td>1970</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuperRockDrillHose;