
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import jetWash5000PsiHose from '../../../../assets/images/product/special-hoses/jet-wash-5000-psi-hose.png';


function JetWash5000PsiHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="jet-wash-5000-psi-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>jet wash-5000 psi hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={jetWash5000PsiHose} alt='jet wash 5000 psi hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>KKN Trading Proprietary.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Specially compounded nitrile and other synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> Two high tensile carbon steel wire braid.<br />
                                            <strong>Cover:</strong> Wrapped finished Weather, abrasion cold and oil resistant synthetic rubber blend.
                                            <br />Black and Blue cover. Pricked cover available on request with minimum order qty of 3000m.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>Designed for application up to -40℃ to + 155℃ / 311℉ maximum.</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>
                                    High pressure power cleaner suitable for dockyard, multiplex, commercial units, garages etc.<br />
                                    <strong className='text-danger'>Strictly not for steam and impulsive hydraulic application.</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>IWM LL06</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>-</td>
                                    <td>13.8</td>
                                    <td>350</td>
                                    <td>5075</td>
                                    <td>1400</td>
                                    <td>20300</td>
                                    <td>50</td>
                                    <td>270</td>
                                </tr>
                                <tr>
                                    <td>IWM LL08</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>-</td>
                                    <td>15.3</td>
                                    <td>350</td>
                                    <td>5075</td>
                                    <td>1400</td>
                                    <td>20300</td>
                                    <td>55</td>
                                    <td>320</td>
                                </tr>
                                <tr>
                                    <td>IWM LL10</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>17.5</td>
                                    <td>350</td>
                                    <td>5075</td>
                                    <td>1400</td>
                                    <td>20300</td>
                                    <td>90</td>
                                    <td>430</td>
                                </tr>
                                <tr>
                                    <td>IWM LL12</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>20.8</td>
                                    <td>350</td>
                                    <td>5075</td>
                                    <td>1400</td>
                                    <td>20300</td>
                                    <td>130</td>
                                    <td>590</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JetWash5000PsiHose;