
import '../../ProductDisplay.scss';
import IndustrialMenu from '../../product-menu/industrial-menu/IndustrialMenu';

import rockDrillHose from '../../../../assets/images/product/industrial-hoses/rock-drill-hose.jpeg';


function RockDrillHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <IndustrialMenu activeLink="rock-drill-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>industrial hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>rock drill hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={rockDrillHose} alt='rock drill hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>IS 446: Type 3 and ISO 2398-Type 2-Class B (Exceeds performance requirement)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Black oil mist resistant specially compounded nitrile and other synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> One high tenacity synthetic textile braid. Two textile braid for 2.0".<br />
                                            <strong>Cover:</strong> Weather, abrasion and oil resistant synthetic rubber blend. (Pin pricked)
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-30 ℃ to + 82 ℃ / -22℉ to + 180℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Suitable for medium duty compressed air mixed with little oil mist service for construction, mining, drilling equipment, oil and gas exploration, steel industry ,quarries and rental industry.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>ITS W 10MF</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>17.0</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>110</td>
                                    <td>215</td>
                                </tr>
                                <tr>
                                    <td>ITS W 12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>21.0</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>130</td>
                                    <td>310</td>
                                </tr>
                                <tr>
                                    <td>ITS W 16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>25.0</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>160</td>
                                    <td>410</td>
                                </tr>
                                <tr>
                                    <td>ITS W 19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>29.0</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>200</td>
                                    <td>540</td>
                                </tr>
                                <tr>
                                    <td>ITS W 25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>36.0</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>250</td>
                                    <td>750</td>
                                </tr>
                                <tr>
                                    <td>ITS W 31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>-</td>
                                    <td>44.0</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>320</td>
                                    <td>1060</td>
                                </tr>
                                <tr>
                                    <td>ITS W 38MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>-</td>
                                    <td>52.0</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>390</td>
                                    <td>1415</td>
                                </tr>
                                <tr>
                                    <td>ITS W 51MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>-</td>
                                    <td>65.0</td>
                                    <td>21</td>
                                    <td>305</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>510</td>
                                    <td>1910</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RockDrillHose;