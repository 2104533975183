
import '../../ProductDisplay.scss';
import HydraulicMenu from '../../product-menu/hydraulic-menu/HydraulicMenu';

import prod100R1highTemperatureHose from '../../../../assets/images/product/hydraulic-hoses/100-r1-high-temperature-hose.jpeg';


function Prod100R1highTemperatureHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <HydraulicMenu activeLink="100-r1-high-temperature-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>hydraulic hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>100 r1 high temperature hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={prod100R1highTemperatureHose} alt='100 r1 high temperature hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>EN857 ISC / ISO 11237-1SC</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> One high tensile carbon steel wire braid.<br />
                                            <strong>Cover:</strong> Oil,weather, abrasion,ozone and heat resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-40 ℃ to + 135 ℃ / -40℉ to + 275℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Hydraulic system low-medium pressure lines and return lines.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>HWS R110006MF</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>11.1</td>
                                    <td>13.4</td>
                                    <td>225</td>
                                    <td>3263</td>
                                    <td>900</td>
                                    <td>13050</td>
                                    <td>75</td>
                                    <td>220</td>
                                </tr>
                                <tr>
                                    <td>HWS R110008MF</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>12.7</td>
                                    <td>15</td>
                                    <td>215</td>
                                    <td>3118</td>
                                    <td>860</td>
                                    <td>12470</td>
                                    <td>86</td>
                                    <td>260</td>
                                </tr>
                                <tr>
                                    <td>HWS R110010MF</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>15.1</td>
                                    <td>17.4</td>
                                    <td>180</td>
                                    <td>2610</td>
                                    <td>720</td>
                                    <td>10440</td>
                                    <td>94</td>
                                    <td>345</td>
                                </tr>
                                <tr>
                                    <td>HWS R110012MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>18.3</td>
                                    <td>20.6</td>
                                    <td>160</td>
                                    <td>2320</td>
                                    <td>640</td>
                                    <td>9280</td>
                                    <td>135</td>
                                    <td>435</td>
                                </tr>
                                <tr>
                                    <td>HWS R110016MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>21.4</td>
                                    <td>23.7</td>
                                    <td>130</td>
                                    <td>1885</td>
                                    <td>520</td>
                                    <td>7540</td>
                                    <td>150</td>
                                    <td>515</td>
                                </tr>
                                <tr>
                                    <td>HWS R110019MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>25.4</td>
                                    <td>27.7</td>
                                    <td>105</td>
                                    <td>1523</td>
                                    <td>420</td>
                                    <td>6090</td>
                                    <td>180</td>
                                    <td>655</td>
                                </tr>
                                <tr>
                                    <td>HWS R110025MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>33.3</td>
                                    <td>35.6</td>
                                    <td>90</td>
                                    <td>1305</td>
                                    <td>360</td>
                                    <td>5220</td>
                                    <td>225</td>
                                    <td>975</td>
                                </tr>
                                <tr>
                                    <td>HWS R110031MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>40.5</td>
                                    <td>43.5</td>
                                    <td>65</td>
                                    <td>943</td>
                                    <td>260</td>
                                    <td>3770</td>
                                    <td>315</td>
                                    <td>1300</td>
                                </tr>
                                <tr>
                                    <td>HWS R110038MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>46.8</td>
                                    <td>50.6</td>
                                    <td>50</td>
                                    <td>725</td>
                                    <td>200</td>
                                    <td>2900</td>
                                    <td>375</td>
                                    <td>1600</td>
                                </tr>
                                <tr>
                                    <td>HWS R110051MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>60.2</td>
                                    <td>64</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>160</td>
                                    <td>2320</td>
                                    <td>473</td>
                                    <td>2100</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prod100R1highTemperatureHose;