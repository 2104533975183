
import '../../ProductDisplay.scss';
import HydraulicMenu from '../../product-menu/hydraulic-menu/HydraulicMenu';

import prod100R32teHose from '../../../../assets/images/product/hydraulic-hoses/100-r3-2te-hose.jpeg';


function Prod100R32teHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <HydraulicMenu activeLink="100-r3-2te-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>hydraulic hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>100 r3 / 2te hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={prod100R32teHose} alt='100 r3 2te hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>SAE J 517-100R3 / EN854 R3 / ISO 4079-2TE / EN854-2TE</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Oil resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> Two high tensile synthetic textile braid.<br />
                                            <strong>Cover:</strong> Oil,weather, abrasion,ozone and heat resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-40 ℃ to + 100 ℃ / -40℉ to + 212℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Hydraulic system low pressure and return lines.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>HTS E06MF</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>-</td>
                                    <td>14.5</td>
                                    <td>87</td>
                                    <td>1262</td>
                                    <td>348</td>
                                    <td>5046</td>
                                    <td>75</td>
                                    <td>165</td>
                                </tr>
                                <tr>
                                    <td>HTS E08MF</td>
                                    <td>5/16"</td>
                                    <td>8</td>
                                    <td>-5</td>
                                    <td>8</td>
                                    <td>-</td>
                                    <td>17.5</td>
                                    <td>84</td>
                                    <td>1218</td>
                                    <td>336</td>
                                    <td>4872</td>
                                    <td>100</td>
                                    <td>250</td>
                                </tr>
                                <tr>
                                    <td>HTS E10MF</td>
                                    <td>3/8"</td>
                                    <td>10</td>
                                    <td>-6</td>
                                    <td>10</td>
                                    <td>-</td>
                                    <td>19.1</td>
                                    <td>78</td>
                                    <td>1131</td>
                                    <td>312</td>
                                    <td>4524</td>
                                    <td>100</td>
                                    <td>280</td>
                                </tr>
                                <tr>
                                    <td>HTS E12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>23.8</td>
                                    <td>70</td>
                                    <td>1015</td>
                                    <td>280</td>
                                    <td>4060</td>
                                    <td>125</td>
                                    <td>400</td>
                                </tr>
                                <tr>
                                    <td>HTS E16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>27.0</td>
                                    <td>61</td>
                                    <td>885</td>
                                    <td>244</td>
                                    <td>3538</td>
                                    <td>140</td>
                                    <td>470</td>
                                </tr>
                                <tr>
                                    <td>HTS E19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>31.8</td>
                                    <td>52</td>
                                    <td>754</td>
                                    <td>208</td>
                                    <td>3016</td>
                                    <td>150</td>
                                    <td>680</td>
                                </tr>
                                <tr>
                                    <td>HTS E25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>38.1</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>160</td>
                                    <td>2320</td>
                                    <td>205</td>
                                    <td>840</td>
                                </tr>
                                <tr>
                                    <td>HTS E31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>-</td>
                                    <td>44.5</td>
                                    <td>26</td>
                                    <td>377</td>
                                    <td>104</td>
                                    <td>1508</td>
                                    <td>250</td>
                                    <td>990</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prod100R32teHose;