
import '../../ProductDisplay.scss';
import DredgeMenu from '../../product-menu/dredge-menu/DredgeMenu';

import expansionHose from '../../../../assets/images/product/dredge-hoses/expansion-hose.jpeg';


function ExpansionHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <DredgeMenu activeLink="expansion-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>dredge hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>expansion hose</h3>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Construction:</strong></td>
                                <td>
                                    <strong>Tube:</strong> Absorbing vibration and noise.<br />
                                    <strong>Reinforcement:</strong> Compensates for misalignment.<br />
                                    <strong>Cover:</strong> High Wear-resistance, good flexibility.
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Expansion joints are also known as bellow hose and compensator. They are designed primarily to reduce the vibration, movement, and noise of the piping system. The expansion joint is installed between the pump and pipeline connector, which is used to reduce the vibration.</td>
                            </tr>
                        </tbody>
                    </table>

                    <img src={expansionHose} alt='expansion hose'></img>

                </div>
            </div>
        </div>
    );
}

export default ExpansionHose;