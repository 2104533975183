
import '../../ProductDisplay.scss';
import IndustrialMenu from '../../product-menu/industrial-menu/IndustrialMenu';

import superSteamHose from '../../../../assets/images/product/industrial-hoses/super-steam-hose.jpeg';


function SuperSteamHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <IndustrialMenu activeLink="super-steam-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>industrial hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>super steam hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={superSteamHose} alt='super steam hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>IS 10655 -Type 3, BS 5342-Type 2 -Non oil resistant cover</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Specially compounded steam heat resistant EPDM rubber.<br />
                                            <strong>Reinforcement:</strong> Two high tensile carbon steel wire braid.<br />
                                            <strong>Cover:</strong> Weather and heat resistant specially compounded EPDM rubber. Pin pricked.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>Maximum operating temperature 232 ℃ (450 ℉)</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Transfer of steam for processing products and cleaning equipments in refiling & petrochemical, paper industry, oil & gas exploration, ship building, industrial cleaning markets etc.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>IWS BB12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>25.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>170</td>
                                    <td>2465</td>
                                    <td>180</td>
                                    <td>500</td>
                                </tr>
                                <tr>
                                    <td>IWS BB16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>29.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>170</td>
                                    <td>2465</td>
                                    <td>200</td>
                                    <td>620</td>
                                </tr>
                                <tr>
                                    <td>IWS BB19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>33.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>170</td>
                                    <td>2465</td>
                                    <td>240</td>
                                    <td>750</td>
                                </tr>
                                <tr>
                                    <td>IWS BB25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>40.0</td>
                                    <td>17</td>
                                    <td>247</td>
                                    <td>170</td>
                                    <td>2465</td>
                                    <td>300</td>
                                    <td>970</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuperSteamHose;