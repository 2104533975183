import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';


function SpiralMenu(props) {

    const linkToActive = props.activeLink;
    const activeLinkClass = (menuName) => {
        let matchWith = '';
        if (menuName === linkToActive) {
            return 'active-menu'
        }
        return matchWith;
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, [linkToActive]);

    return (
        <div className='display-product-menu-wrapper'>
            <h3 className='product-menu-title'>Industrial Hoses</h3>
            <div className='product-menu-list'>
                <div className={"product-menu-item " + activeLinkClass('hipulse-4sp')}>
                    <NavLink to="/products/spiral/hipulse-4sp">hipulse 4sp</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('hipulse-4sh')}>
                    <NavLink to="/products/spiral/hipulse-4sh">hipulse 4sh</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('hipulse-r12')}>
                    <NavLink to="/products/spiral/hipulse-r12">hipulse r12</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('hipulse-r13')}>
                    <NavLink to="/products/spiral/hipulse-r13">hipulse r13</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('hipulse-r15')}>
                    <NavLink to="/products/spiral/hipulse-r15">hipulse r15</NavLink>
                </div>
            </div>
        </div>
    );
}

export default SpiralMenu;