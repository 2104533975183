
import '../../ProductDisplay.scss';
import DredgeMenu from '../../product-menu/dredge-menu/DredgeMenu';

import dischargeDredgingHose from '../../../../assets/images/product/dredge-hoses/discharge-dredging-hose.jpeg';


function DischargeDredgingHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <DredgeMenu activeLink="discharge-dredging-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>dredge hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>discharge dredging hose</h3>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Construction:</strong></td>
                                <td>
                                    <strong>Tube:</strong> Easy to install, flexible to use and safe.<br />
                                    <strong>Cover:</strong> Abrasion-resistant and bending-resistant.
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-20 ℃ to + 50 ℃</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Discharge dredging hose is part of dredge pipeline for dredger, usually with the pipe floater, HDPE pipe, or steel pipe connected in one set of pipeline. In the working environment with high wind and waves, the discharge rubber hose can rely on its own softness to offset part of the wind, so as to ensure the safety of the whole pipeline.</td>
                            </tr>
                        </tbody>
                    </table>

                    <img src={dischargeDredgingHose} alt='discharge dredging hose'></img>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>NOTE:</strong></td>
                                <td>Up to NB Backup Flanges and above 500 NB Integrated Nipple Flanges will be used.<br />
                                    **Bend angle can be custom and varied depending on the service condition and customer requirement.
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='dredge-hose-table'>
                            <caption><h3>dredge discharge hose:</h3></caption>
                            <tbody>
                                <tr>
                                    <th>DIAMETER(NB)</th>
                                    <th>LENGTH(mm)</th>
                                    <th>PRESSURE KG/Cm2</th>
                                    <th>BAND ANGLE*</th>
                                </tr>
                                <tr>
                                    <td>250</td>
                                    <td>3000</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>250</td>
                                    <td>3000</td>
                                    <td>10</td>
                                    <td>25**</td>
                                </tr>
                                <tr>
                                    <td>300</td>
                                    <td>3000</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>300</td>
                                    <td>3000</td>
                                    <td>10</td>
                                    <td>25**</td>
                                </tr>
                                <tr>
                                    <td>400</td>
                                    <td>3000</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>500</td>
                                    <td>2000</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>600</td>
                                    <td>2000</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>650</td>
                                    <td>2000</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>650</td>
                                    <td>3000</td>
                                    <td>20</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>650</td>
                                    <td>6000</td>
                                    <td>10</td>
                                    <td>25**</td>
                                </tr>
                                <tr>
                                    <td>700</td>
                                    <td>3000</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>750</td>
                                    <td>2000</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>750</td>
                                    <td>3000</td>
                                    <td>20</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>750</td>
                                    <td>6000</td>
                                    <td>10</td>
                                    <td>25**</td>
                                </tr>
                                <tr>
                                    <td>850</td>
                                    <td>2000</td>
                                    <td>10</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>850</td>
                                    <td>3000</td>
                                    <td>20</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>850</td>
                                    <td>6000</td>
                                    <td>10</td>
                                    <td>25**</td>
                                </tr>
                                <tr>
                                    <td>900</td>
                                    <td>6000</td>
                                    <td>10</td>
                                    <td>25**</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DischargeDredgingHose;