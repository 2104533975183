import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';


function DredgeMenu(props) {

    const linkToActive = props.activeLink;
    const activeLinkClass = (menuName) => {
        let matchWith = '';
        if (menuName === linkToActive) {
            return 'active-menu'
        }
        return matchWith;
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, [linkToActive]);

    return (
        <div className='display-product-menu-wrapper'>
            <h3 className='product-menu-title'>Industrial Hoses</h3>
            <div className='product-menu-list'>
                <div className={"product-menu-item " + activeLinkClass('discharge-dredging-hose')}>
                    <NavLink to="/products/dredge/discharge-dredging-hose">discharge dredging hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('suction-dredging-hose')}>
                    <NavLink to="/products/dredge/suction-dredging-hose">suction dredging hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('floating-hose')}>
                    <NavLink to="/products/dredge/floating-hose">floating hose</NavLink>
                </div>
                <div className={"product-menu-item " + activeLinkClass('expansion-hose')}>
                    <NavLink to="/products/dredge/expansion-hose">expansion hose</NavLink>
                </div>
            </div>
        </div>
    );
}

export default DredgeMenu;