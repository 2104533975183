
import '../../ProductDisplay.scss';
import SpecialMenu from '../../product-menu/special-menu/SpecialMenu';

import prod2textileLpgHose from '../../../../assets/images/product/special-hoses/2textile-lpg-hose.png';


function Prod2textileLpgHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <SpecialMenu activeLink="2textile-lpg-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>special hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>2-textile lpg hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={prod2textileLpgHose} alt='2textile lpg hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>IS 9573-Type 2 / BS 1762 D / ISO 1307</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> LPG resistant synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> Two high tensile synthetic textile braid. Antistatic copper wire.<br />
                                            <strong>Cover:</strong> Black synthetic rubber blend. <strong>Pin pricked.</strong><br />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-30℃ to + 65℃ / -22℉ to + 149℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Transfer and delivery of LPG ( Propane and Butane) in liquid or vapour form. 
                                    Transfer of natural gas in open ventilated areas ( 1 psiG maximum working pressure)
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>ITS 1LL 06MF</td>
                                    <td>1/4"</td>
                                    <td>6.3</td>
                                    <td>-4</td>
                                    <td>6</td>
                                    <td>-</td>
                                    <td>16.6</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>150</td>
                                    <td>240</td>
                                </tr>
                                <tr>
                                    <td>ITS 1LL 12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>23.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>150</td>
                                    <td>340</td>
                                </tr>
                                <tr>
                                    <td>ITS 1LL 19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>31.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>225</td>
                                    <td>570</td>
                                </tr>
                                <tr>
                                    <td>ITS 1LL 25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>38.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>300</td>
                                    <td>760</td>
                                </tr>
                                <tr>
                                    <td>ITS 1LL 31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>-</td>
                                    <td>46.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>380</td>
                                    <td>1050</td>
                                </tr>
                                <tr>
                                    <td>ITS 1LL38MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>-</td>
                                    <td>52.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>450</td>
                                    <td>1240</td>
                                </tr>
                                <tr>
                                    <td>ITS 1LL 51MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>-</td>
                                    <td>67.0</td>
                                    <td>25</td>
                                    <td>363</td>
                                    <td>100</td>
                                    <td>1450</td>
                                    <td>600</td>
                                    <td>2000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prod2textileLpgHose;