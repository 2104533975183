
import '../../ProductDisplay.scss';
import IndustrialMenu from '../../product-menu/industrial-menu/IndustrialMenu';

import superSandBlastHose from '../../../../assets/images/product/industrial-hoses/super-sand-blast-hose.jpeg';


function SuperSandBlastHose() {
    return (
        <div className='section-display-products'>
            <div className='display-products-container'>
                <IndustrialMenu activeLink="super-sand-blast-hose" />

                <div className='display-product-info-wrapper'>
                    <div className='product-name'>
                        <h2>industrial hoses</h2>
                    </div>
                    <div className='product-variant-name'>
                        <h3>super sand blast hose</h3>
                    </div>

                    <div className='product-description-with-img'>
                        <div className='product-image'>
                            <img src={superSandBlastHose} alt='super sand blast hose'></img>
                        </div>
                        <div className='product-description'>
                            <table className='description-table'>
                                <tbody>
                                    <tr>
                                        <td><strong>Reference standard:</strong></td>
                                        <td>IS 5894: 2005 / ISO 3861: 2008 (Increased tube thickness)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Construction:</strong></td>
                                        <td>
                                            <strong>Tube:</strong> Static dissipating synthetic rubber blend.<br />
                                            <strong>Reinforcement:</strong> Two high tenacity synthetic textile braid with antistatic copper wire.<br />
                                            <strong>Cover:</strong> Black wrapped finished weather, abrasion resistant synthetic rubber blend.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className='description-table'>
                        <tbody>
                            <tr>
                                <td><strong>Working temperature:</strong></td>
                                <td>-30 ℃ to + 80 ℃ / -22℉ to + 176℉</td>
                            </tr>
                            <tr>
                                <td><strong>Application:</strong></td>
                                <td>Convey wet and dry sand, grit blasting materials for cleaning, cutting or finishing casting, stone, glass and metal surfaces.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='scrollable-product-info-table-wrapper'>
                        <table className='detailed-table'>
                            <tbody>
                                <tr>
                                    <th>PRODUCT CODE</th>
                                    <th colSpan={4}>HOSE SIZE</th>
                                    <th>BRAID OD</th>
                                    <th>OD</th>
                                    <th colSpan={2}>MAXIMUM WORKING PRESSURE</th>
                                    <th colSpan={2}>MINIMUM BURST PRESSURE</th>
                                    <th>MINIMUM BEND RADIUS</th>
                                    <th>WEIGHT</th>
                                </tr>
                                <tr>
                                    <th>KKN Trading</th>
                                    <th>inch</th>
                                    <th>metric mm</th>
                                    <th>dash</th>
                                    <th>DN</th>
                                    <th>mm</th>
                                    <th>mm</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>bar</th>
                                    <th>psi</th>
                                    <th>mm</th>
                                    <th>gm/m</th>
                                </tr>
                                <tr>
                                    <td>ITS 1BB12MF</td>
                                    <td>1/2"</td>
                                    <td>12.5</td>
                                    <td>-8</td>
                                    <td>12</td>
                                    <td>-</td>
                                    <td>32.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>130</td>
                                    <td>810</td>
                                </tr>
                                <tr>
                                    <td>ITS 1BB16MF</td>
                                    <td>5/8"</td>
                                    <td>16</td>
                                    <td>-10</td>
                                    <td>16</td>
                                    <td>-</td>
                                    <td>36.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>180</td>
                                    <td>950</td>
                                </tr>
                                <tr>
                                    <td>ITS 1BB19MF</td>
                                    <td>3/4"</td>
                                    <td>19</td>
                                    <td>-12</td>
                                    <td>19</td>
                                    <td>-</td>
                                    <td>39.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>210</td>
                                    <td>1070</td>
                                </tr>
                                <tr>
                                    <td>ITS 1BB25MF</td>
                                    <td>1.0"</td>
                                    <td>25</td>
                                    <td>-16</td>
                                    <td>25</td>
                                    <td>-</td>
                                    <td>47.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>310</td>
                                    <td>1470</td>
                                </tr>
                                <tr>
                                    <td>ITS 1BB31MF</td>
                                    <td>11/4"</td>
                                    <td>31.5</td>
                                    <td>-20</td>
                                    <td>31</td>
                                    <td>-</td>
                                    <td>54.5</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>330</td>
                                    <td>1850</td>
                                </tr>
                                <tr>
                                    <td>ITS 1BB38MF</td>
                                    <td>11/2"</td>
                                    <td>38</td>
                                    <td>-24</td>
                                    <td>38</td>
                                    <td>-</td>
                                    <td>61.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>470</td>
                                    <td>2120</td>
                                </tr>
                                <tr>
                                    <td>ITS 1BB51MF</td>
                                    <td>2.0"</td>
                                    <td>51</td>
                                    <td>-32</td>
                                    <td>51</td>
                                    <td>-</td>
                                    <td>74.0</td>
                                    <td>10</td>
                                    <td>145</td>
                                    <td>40</td>
                                    <td>580</td>
                                    <td>550</td>
                                    <td>2680</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuperSandBlastHose;